import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { listHero } from "../actions/heroActions";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Message from "./Message";

const getConfigurableProps = () => ({
  showArrows: true,
  showStatus: true,
  showIndicators: true,
  infiniteLoop: true,
  showThumbs: false,
  useKeyboardArrows: true,
  autoPlay: true,
  stopOnHover: true,
  swipeable: true,
  dynamicHeight: false,
  emulateTouch: true,
  autoFocus: false,
  thumbWidth: 100,
  selectedItem: 0,
  interval: 4000,
  transitionTime: 900,
  ariaLabel: "ariaLabel",
});

export default function RingsHero(props) {
  const dispatch = useDispatch();

  const heroList = useSelector((state) => state.heroList);
  const { loading, heros, error } = heroList;

  const [country, setCountry] = useState("india");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { i18n } = useTranslation();
  let lang = i18n.language;

  useEffect(() => {
    if (lang === "en") {
      setCountry("india");
    } else {
      setCountry("germany");
    }
  }, [lang, i18n]);

  useEffect(() => {
    dispatch(listHero(country, props.category));
  }, [country, dispatch, props]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {loading ? (
        <div className="w-full min-h-[20vh] md:min-h-[70vh] bg-white animate-pulse">
          <div className="h-full bg-gradient-to-r from-gray-200 to-gray-300 bg-no-repeat bg-animate animate-pulse"></div>
        </div>
      ) : error ? (
        <Message type="error">{error}</Message>
      ) : (
        <div className="w-full bg-white">
          <Carousel {...getConfigurableProps()}>
            {heros.map((hero, index) => (
              <div key={index}>
                <img
                  loading="lazy"
                  src={windowWidth <= 768 ? hero.urlMobImg : hero.url}
                  alt="banner"
                  className="w-full h-full object-cover object-center"
                />
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
}
