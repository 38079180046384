import { useEffect, useState } from "react";

const ProductImages = ({ product, variantId }) => {
  console.log(variantId);
  // State to manage the currently displayed images
  const [currentImages, setCurrentImages] = useState(
    product.variants?.filter((variant) => variant._id === variantId)[0]
      ?.images || []
  );
  const [isDragging, setIsDragging] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    setCurrentImages(
      product.variants?.filter((variant) => variant._id === variantId)[0]
        ?.images
    );
  }, [variantId]);

  // Function to swap the clicked image with the first image
  const handleImageClick = (index) => {
    if (index === 0) return; // If the first image is clicked, do nothing

    // Swap the clicked image with the first image
    const newImages = [...currentImages];
    [newImages[0], newImages[index]] = [newImages[index], newImages[0]];
    setCurrentImages(newImages);
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseOut = () => {
    setZoom(1);
    setIsZoomed(false);
    setImagePosition({ x: 0, y: 0 });
  };

  const handleMouseMove = (e) => {
    const image = e.target;
    const imageRect = image.getBoundingClientRect();
    const x = ((e.clientX - imageRect.left) / imageRect.width) * 100;
    const y = ((e.clientY - imageRect.top) / imageRect.height) * 100;
    setImagePosition({ x, y });
  };

  const handleTouchStart = () => {
    setZoom(2.5);
    setIsZoomed(true);
  };

  const handleTouchMove = (e) => {
    e.stopPropagation();
    const image = e.target;
    const imageRect = image.getBoundingClientRect();
    const x = ((e.touches[0].clientX - imageRect.left) / imageRect.width) * 100;
    const y = ((e.touches[0].clientY - imageRect.top) / imageRect.height) * 100;
    setImagePosition({ x, y });
  };

  const handleTouchOut = (e) => {
    e.preventDefault();

    setZoom(1);
    setIsZoomed(false);
    setImagePosition({ x: 0, y: 0 });
  };

  const handleMouseOver = () => {
    setZoom(2.5);
    setIsZoomed(true);
  };

  const imageStyle = {
    transform: `scale(${zoom})`,
    transition: isZoomed ? "transform 0.5s" : "none",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    transformOrigin: `${imagePosition.x}% ${imagePosition.y}%`,
  };

  return (
    // <div className="grid grid-cols-2 gap-2">
    //   {currentImages?.map((img, index) => (
    //     // <div
    //     //   key={index}
    //     //   className={`col-span-${
    //     //     index === 0 ? "2" : "1"
    //     //   } border cursor-pointer`}
    //     //   onClick={() => handleImageClick(index)}
    //     // >
    //     //   <img src={img} alt={`Product-image-${index + 1}`} />
    //     // </div>
    //     <div
    //       key={index}
    //       className={`col-span-${
    //         index === 0 ? "2" : "1"
    //       } border cursor-pointer`}
    //       onClick={() => handleImageClick(index)}
    //     >
    //       <div
    //         className="overflow-hidden hidden md:block"
    //         onMouseDown={handleMouseDown}
    //         onMouseOut={handleMouseOut}
    //         onMouseMove={handleMouseMove}
    //         onTouchStart={handleTouchStart}
    //         onTouchMove={handleTouchMove}
    //         onTouchCancel={handleTouchOut}
    //         onMouseOver={handleMouseOver}
    //       >
    //         <img
    //           src={img}
    //           className="w-100 h-100 object-fit-contain mw-100"
    //           style={imageStyle}
    //           alt="image"
    //         />
    //       </div>
    //       <div className="overflow-hidden block md:hidden">
    //         <img
    //           src={img}
    //           className="w-100 h-100 object-fit-contain mw-100"
    //           style={imageStyle}
    //           alt="image"
    //         />
    //       </div>
    //     </div>
    //   ))}
    // </div>
    <div className="grid grid-cols-2 gap-2">
      {currentImages?.map((img, index) => (
        <div
          key={index}
          className={`col-span-${
            index === 0 ? "2" : "1"
          } border cursor-pointer`}
          onClick={() => handleImageClick(index)}
        >
          {index === 0 ? (
            <>
              <div
                className="overflow-hidden hidden md:block"
                onMouseOut={handleMouseOut}
                onMouseMove={handleMouseMove}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchCancel={handleTouchOut}
                onMouseOver={handleMouseOver}
              >
                <img
                  src={img}
                  className="w-100 h-100 object-fit-contain mw-100"
                  style={imageStyle}
                  alt={`Product-image-${index + 1}`}
                />
              </div>
              <div className="overflow-hidden block md:hidden">
                <img
                  src={img}
                  className="w-100 h-100 object-fit-contain mw-100"
                  style={imageStyle}
                  alt="image"
                />
              </div>
              <img
                src={`https://www.facebook.com/tr?id=1327632928208462&ev=ViewContent&cd[content_name]=${product.name}&cd[content_category]=${product?.categories[0]?.name}&cd[content_type]=product&cd[content_ids]=${product.sku}&cd[value]=${product.price}&cd[currency]=INR`}
                height="1"
                width="1"
                style={{ display: "none" }}
              />
            </>
          ) : (
            <div className="overflow-hidden">
              <img
                src={img}
                className="w-100 h-100 object-fit-contain mw-100"
                alt={`Product-image-${index + 1}`}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ProductImages;
