import React, { Fragment, useState } from "react";
// import { initializeApp } from 'firebase/app';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
// import "react-phone-number-input/style.css";
import OtpInput from "otp-input-react";

import { auth } from "../config/firebase.config";
import { useDispatch } from "react-redux";
import { register } from "../actions/userActions";
import { useSelector } from "react-redux";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useEffect } from "react";
import { USER_REGISTER_RESET } from "../constants/userConstants";
import Message from "../components/Message";
import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import axios from "axios";
import { serverIp } from "../config/conf";
import { Dialog, Transition } from "@headlessui/react";
import { scrollToTop } from "../components/ScrollToTop";

// const firebaseConfig = {
//   apiKey: "your-api-key",
//   authDomain: "your-auth-domain",
//   projectId: "your-project-id",
//   storageBucket: "your-storage-bucket",
//   messagingSenderId: "your-messaging-sender-id",
//   appId: "your-app-id"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);

const RegistrationForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    // password: "",
    // confirmPassword: "",
    otpPhone: "",
    otpEmail: "",
  });

  const [resendTimeout, setResendTimeout] = useState(30);
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpVerifiedMail, setOtpVerifiedMail] = useState(false);
  const [error, setError] = useState("");
  const [idToken, setIdToken] = useState(null);
  const [uid, setUid] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [otpLoading, setOtpLoading] = useState(false);
  const [otpVerifying, setOtpVerifying] = useState(false);

  const userRegister = useSelector((state) => state.userRegister);
  const { error: errorUSer, userInfo, success } = userRegister;

  useEffect(() => {
    if (otpSent && resendTimeout > 0) {
      const timer = setTimeout(
        () => setResendTimeout((prev) => prev - 1),
        1000
      );
      return () => clearTimeout(timer);
    } else if (resendTimeout === 0) {
      setOtpSent(false); // Re-enable the Send OTP button
      setResendTimeout(30); // Reset timer
    }
  }, [otpSent, resendTimeout]);

  useEffect(() => {
    scrollToTop();
    if (userInfo?.success) {
      navigate("/signup");
    }
    dispatch({ type: USER_REGISTER_RESET });
  }, [navigate, userInfo]);

  // const handleInputChange = (value, name) => {
  //   setForm({ ...form, [name]: value });
  // };
  const handleInputChange = (value, name) => {
    setForm((prevForm) => ({ ...prevForm, [name]: value }));

    if (name === "phone") {
      setPhoneError(isValidPhoneNumber(value) ? "" : "Invalid phone number");
    } else if (name === "email") {
      setEmailError(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? "" : "Invalid email"
      );
    }
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(phone)) {
      setPhoneError("Please enter a valid 10-digit phone number.");
      return false;
    }
    setPhoneError("");
    return true;
  };

  const sendOtp = async (e) => {
    e.preventDefault();
    setOtpLoading(true);

    if (!validatePhoneNumber(form.phone) || !validateEmail(form.email)) {
      return;
    }

    if (!form.phone) {
      setError("Please enter a valid phone number");
      return;
    } else if (!form.email) {
      setError("Please enter a valid email");
      return;
    } else {
      setError("");
    }

    setError("");

    // Initialize RecaptchaVerifier if not already initialized
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container2",
        {
          size: "invisible",
          callback: (response) => {
            // reCAPTCHA solved, allow the user to proceed with the OTP request
            console.log("Recaptcha verified");
          },
          "expired-callback": () => {
            // Response expired, reset reCAPTCHA
            console.error("Recaptcha expired");
          },
        },
        auth
      );
    }

    try {
      // send OTP on E-Mail
      const { data } = await axios.post(`${serverIp}/api/users/requestotp`, {
        email: form.email,
        phone: `+91${form.phone}`,
      });
      if (data?.success) {
        // Send OTP using Firebase
        const appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(auth, `+91${form.phone}`, appVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            setOtpSent(true);
            setOtpLoading(false);
            openModal();
            console.log("OTP sent");
          })
          .catch((err) => {
            console.log(err);
            setError("Error sending OTP: " + err.message);
          });
      } else {
        setError("User Already Exist, Please Login");
        setOtpLoading(false);
      }
    } catch (error) {
      setError("User Already Exist, Please Login");
      setOtpLoading(false);
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setOtpVerifying(true);
    if (!form.otpPhone) {
      setError("Please enter the OTP");
      return;
    }

    setError("");

    try {
      const { data } = await axios.post(`${serverIp}/api/users/validateotp`, {
        email: form.email,
        otp: form.otpEmail,
      });

      if (data.success) {
        setOtpVerifiedMail(true);
      } else {
        console.log(data.message);
        setOtpVerifiedMail(false);
        setError(data.message);
      }

      window.confirmationResult
        .confirm(form.otpPhone)
        .then((result) => {
          const user = result.user;

          const uid = user.uid;
          document.getElementById("recaptcha-container2").style.display =
            "none";
          setOtpVerified(true);
          setOtpVerifying(false);

          // Now you can get the Firebase ID token
          user.getIdToken().then((idToken) => {
            // Send the token to your backend for verification
            // dispatch(login(phone, idToken, uid));
            setIdToken(idToken);
            setUid(uid);
            dispatch(
              register(
                form.firstName,
                form.lastName,
                form.email,
                `+91${form.phone}`,
                // form.password,
                true,
                idToken,
                uid
              )
            );
          });
        })
        .catch((err) => {
          setError("Invalid OTP");
        });
    } catch (error) {
      setError("Invalid OTP");
    }

    closeModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!otpVerified) {
      setError("Please verify OTP before submitting the form");
      return;
    }

    // if (form.password !== form.confirmPassword) {
    //   setError("Passwords do not match");
    //   return;
    // }

    dispatch(
      register(
        form.firstName,
        form.lastName,
        form.email,
        `+91${form.phone}`,
        // form.password,
        otpVerified,
        idToken,
        uid
      )
    );
    // Call your registration API here
    console.log("Registration successful:", form);
  };

  const validateEmail = (mail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(mail)) {
      setEmailError("Please enter a valid email address.");
      return false;
    }
    setEmailError("");
    return true;
  };

  return (
    <div className="flex flex-col md:flex-row border-b justify-between gap-10 px-4 lg:px-8 py-4 bg-white">
      <div className="flex flex-col items-center w-full md:w-1/2 p-4 md:p-10 space-y-8">
        <div className="flex flex-col w-full md:w-[90%]">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900 mb-3">
            Create Your Account
          </h2>
          <h3 className="font-semibold text-[14px]">
            Please create your SAIRAH Account.
          </h3>
        </div>
        <form className="mt-8 space-y-6 w-full md:w-[90%]" id="formRegister">
          {errorUSer && <Message type="error">{errorUSer}</Message>}
          {error !== "" && <Message type="error">{error}</Message>}
          <div className="flex flex-col gap-4 -space-y-px rounded-md">
            <div>
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={form.firstName}
                onChange={(e) => handleInputChange(e.target.value, "firstName")}
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div>
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={form.lastName}
                onChange={(e) => handleInputChange(e.target.value, "lastName")}
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div>
              <FormControl isInvalid={emailError}>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={form.email}
                  onChange={(e) => {
                    handleInputChange(e.target.value, "email");
                    validateEmail(e.target.value);
                  }}
                  // onBlur={validateEmail}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  required
                />
                {emailError && (
                  <FormErrorMessage>{emailError}</FormErrorMessage>
                )}
              </FormControl>
            </div>
            <div>
              {/* <PhoneInput
                className=""
                defaultCountry="IN"
                international
                placeholder="Phone"
                value={form.phone}
                onChange={(value) => handleInputChange(value, "phone")}
                required
              /> */}
              <FormControl isInvalid={phoneError}>
                <InputGroup>
                  <InputLeftAddon px="4" py="5">
                    +91
                  </InputLeftAddon>
                  {/* <Input
                  type="tel"
                  name="Phone"
                  placeholder="phone"
                  value={form.phone}
                  onChange={(e) => handleInputChange(e.target.value, "phone")}
                  onBlur={validatePhoneNumber}
                  width="full"
                  px="4"
                  py="5"
                  border="1px"
                  borderColor="gray.300"
                  rounded="md"
                  required
                /> */}
                  <Input
                    type="tel"
                    placeholder="Phone"
                    value={form.phone}
                    onChange={(e) => {
                      handleInputChange(e.target.value, "phone");
                      validatePhoneNumber(e.target.value);
                    }}
                    // onBlur={validatePhoneNumber}
                    maxLength={10}
                    width="full"
                    px="4"
                    py="5"
                    border="1px"
                    borderColor="gray.300"
                    rounded="md"
                    required
                  />
                </InputGroup>
                {phoneError && (
                  <FormErrorMessage>{phoneError}</FormErrorMessage>
                )}
              </FormControl>
              {/* <input
                type="text"
                name="Phone"
                placeholder="Phone"
                value={form.phone}
                onChange={(e) => handleInputChange(e.target.value, "phone")}
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
                required
              /> */}
            </div>
            {/* <button
              onClick={sendOtp}
              disabled={phoneError || emailError || !form.phone || !form.email}
              className={`bg-[#000080] text-white py-2 px-4 rounded-md w-full ${
                phoneError || emailError || !form.phone || !form.email
                  ? "cursor-not-allowed bg-gray-500"
                  : "hover:bg-[#000090]"
              }`}
              
            >
              {otpSent ? "Resend OTP" : "Send OTP"}
            </button> */}
            <Button
              onClick={sendOtp}
              bgColor="#000080"
              _hover={{ bgColor: "#000090" }}
              color="white"
              w="full"
              py="2"
              isLoading={otpLoading}
              loadingText="Loading..."
              fontSize="sm"
              isDisabled={
                phoneError || emailError || !form.phone || !form.email
              }
            >
              {otpSent ? "Resend OTP" : "Send OTP"}
            </Button>

            {/* {otpVerified && otpVerifiedMail && ( */}
            {/* <>
                <div>
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={form.password}
                    onChange={(e) =>
                      handleInputChange(e.target.value, "password")
                    }
                    className="w-full px-4 py-2 border border-gray-300 rounded-md"
                    required
                  />
                </div>
                <div>
                  <input
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    value={form.confirmPassword}
                    onChange={(e) =>
                      handleInputChange(e.target.value, "confirmPassword")
                    }
                    className="w-full px-4 py-2 border border-gray-300 rounded-md"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="bg-[#000080] text-white py-2 px-4 rounded-md w-full hover:bg-[#000090]"
                >
                  Register
                </button>
              </> */}
            {/* )} */}
          </div>
        </form>
        {/* {error && <Message type="error">{error}</Message>} */}
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    OTP Validations
                  </Dialog.Title>
                  {error !== "" && <Message type="error">{error}</Message>}
                  <div className="mt-2">
                    <div className="mt-4">
                      <label>Enter Phone OTP</label>
                      <OtpInput
                        value={form.otpPhone}
                        onChange={(value) =>
                          handleInputChange(value, "otpPhone")
                        }
                        OTPLength={6}
                        autoFocus
                        otpType="number"
                        disabled={false}
                        inputStyles={{
                          width: "100%", // Allows input width to fill container in mobile view
                          maxWidth: "40px", // Sets a maximum width to prevent overflow on larger screens
                          margin: "0 4px", // Simplifies margin for better spacing
                          fontSize: "16px", // Slightly larger for readability
                          textAlign: "center",
                          padding: "4px", // Minimal padding to prevent text clipping
                          borderRadius: "6px",
                          border: "1px solid #ccc",
                        }}
                        className="opt-container flex w-full justify-center px-2 py-2"
                      />
                      {/* <button
                        onClick={verifyOtp}
                        className="bg-[#000080] text-white py-2 px-4 rounded-md mt-2 w-full hover:bg-[#000090]"
                      >
                        Verify OTP
                      </button> */}
                    </div>
                    <div className="mt-4">
                      <label>Enter Email OTP</label>
                      <OtpInput
                        value={form.otpEmail}
                        onChange={(value) =>
                          handleInputChange(value, "otpEmail")
                        }
                        OTPLength={6}
                        autoFocus
                        otpType="number"
                        disabled={false}
                        inputStyles={{
                          width: "100%", // Allows input width to fill container in mobile view
                          maxWidth: "40px", // Sets a maximum width to prevent overflow on larger screens
                          margin: "0 4px", // Simplifies margin for better spacing
                          fontSize: "16px", // Slightly larger for readability
                          textAlign: "center",
                          padding: "4px", // Minimal padding to prevent text clipping
                          borderRadius: "6px",
                          border: "1px solid #ccc",
                        }}
                        className="opt-container flex w-full justify-center px-2 py-2"
                      />
                      {/* <button
                        onClick={verifyOtp}
                        className="bg-[#000080] text-white py-2 px-4 rounded-md mt-2 w-full hover:bg-[#000090]"
                      >
                        Verify OTP
                      </button> */}
                      <Button
                        type="button"
                        onClick={verifyOtp}
                        bgColor="#000080"
                        _hover={{ bgColor: "#000090" }}
                        color="white"
                        isLoading={otpVerifying}
                        loadingText="Loading..."
                        w="full"
                        py="2"
                        fontSize="sm"
                      >
                        Verify OTP
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <div className="flex justify-center items-center">
        <div className="border-b-2 md:border-b-0 border-l-0 md:border-l-2 border-gray-300 w-full md:w-2 h-0 md:h-[500px]"></div>
      </div>

      <div className="flex items-center flex-col w-full md:w-1/2 p-4 md:p-10 space-y-8">
        <div className="flex flex-col gap-4 md:gap-10 w-full md:w-[90%] mb-4">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900 mb-3">
            Already have an Account?
          </h2>
          <h3 className="font-semibold text-[14px] leading-10">
            Sign In to access your account, saved items, and order history.
          </h3>
        </div>
        <div className="flex items-center w-full md:w-[90%]">
          <RouterLink
            to="/signup"
            className="flex w-full justify-center rounded-md bg-[#000080] px-3 py-2 text-sm font-semibold text-white hover:bg-[#000090] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Sign In
          </RouterLink>
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
