export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL';

export const PRODUCT_SEARCH_LIST_REQUEST = 'PRODUCT_SEARCH_LIST_REQUEST';
export const PRODUCT_SEARCH_LIST_SUCCESS = 'PRODUCT_SEARCH_LIST_SUCCESS';
export const PRODUCT_SEARCH_LIST_FAIL = 'PRODUCT_SEARCH_LIST_FAIL';

export const RELATED_PRODUCT_LIST_REQUEST = 'RELATED_PRODUCT_LIST_REQUEST';
export const RELATED_PRODUCT_LIST_SUCCESS = 'RELATED_PRODUCT_LIST_SUCCESS';
export const RELATED_PRODUCT_LIST_FAIL = 'RELATED_PRODUCT_LIST_FAIL';

export const YMAL_PRODUCT_LIST_REQUEST = 'YMAL_PRODUCT_LIST_REQUEST';
export const YMAL_PRODUCT_LIST_SUCCESS = 'YMAL_PRODUCT_LIST_SUCCESS';
export const YMAL_PRODUCT_LIST_FAIL = 'YMAL_PRODUCT_LIST_FAIL';

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST';
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS';
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL';

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL';

export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST';
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS';
export const PRODUCT_CREATE_FAIL = 'PRODUCT_CREATE_FAIL';
export const PRODUCT_CREATE_RESET = 'PRODUCT_CREATE_RESET';

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST';
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL';
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET';

export const PRODUCT_REVIEW_CREATE_REQUEST = 'PRODUCT_REVIEW_CREATE_REQUEST';
export const PRODUCT_REVIEW_CREATE_SUCCESS = 'PRODUCT_REVIEW_CREATE_SUCCESS';
export const PRODUCT_REVIEW_CREATE_FAIL = 'PRODUCT_REVIEW_CREATE_FAIL';
export const PRODUCT_REVIEW_CREATE_RESET = 'PRODUCT_REVIEW_CREATE_RESET';
