import axios from "axios";

import { 
    HERO_LIST_REQUEST, 
    HERO_LIST_SUCCESS, 
    HERO_LIST_FAIL,
    HERO_CREATE_FAIL,
    HERO_CREATE_REQUEST,
    HERO_CREATE_SUCCESS,
    HERO_DELETE_FAIL,
    HERO_DELETE_REQUEST,
    HERO_DELETE_SUCCESS,
    HERO_DETAILS_FAIL,
    HERO_DETAILS_REQUEST,
    HERO_DETAILS_SUCCESS,
    HERO_UPDATE_FAIL,
    HERO_UPDATE_REQUEST,
    HERO_UPDATE_SUCCESS,
	HERO_LATEST_LIST_FAIL,
	HERO_LATEST_LIST_REQUEST,
	HERO_LATEST_LIST_SUCCESS,
	HERO_COLLECTION_LIST_REQUEST,
	HERO_COLLECTION_LIST_SUCCESS,
	HERO_COLLECTION_LIST_FAIL,
	HERO_FOOTER_LIST_REQUEST,
	HERO_FOOTER_LIST_SUCCESS,
	HERO_FOOTER_LIST_FAIL
} from "../constants/heroConstants";
import { serverIp } from "../config/conf";

export const listHero = (country, category) => async (dispatch) => {
    try {
        dispatch({ type: HERO_LIST_REQUEST });
        const { data } = await axios.get(`${serverIp}/api/hero?country=${country}&category=${category}`);
        dispatch({ type: HERO_LIST_SUCCESS, payload: data });

    } catch (err) {
        dispatch({
            type: HERO_LIST_FAIL,
            payload:
                err.response && err.response.data.message
                    ? err.response.data.message
                    : err.message,
        });
    }
};

export const listHeroLatest = (country, category) => async (dispatch) => {
    try {
        dispatch({ type: HERO_LATEST_LIST_REQUEST });
        const { data } = await axios.get(`${serverIp}/api/hero?country=${country}&category=${category}`);
        dispatch({ type: HERO_LATEST_LIST_SUCCESS, payload: data });

    } catch (err) {
        dispatch({
            type: HERO_LATEST_LIST_FAIL,
            payload:
                err.response && err.response.data.message
                    ? err.response.data.message
                    : err.message,
        });
    }
};

export const listHeroCollection = (country, category) => async (dispatch) => {
    try {
        dispatch({ type: HERO_COLLECTION_LIST_REQUEST });
        const { data } = await axios.get(`${serverIp}/api/hero?country=${country}&category=${category}`);
        dispatch({ type: HERO_COLLECTION_LIST_SUCCESS, payload: data });

    } catch (err) {
        dispatch({
            type: HERO_COLLECTION_LIST_FAIL,
            payload:
                err.response && err.response.data.message
                    ? err.response.data.message
                    : err.message,
        });
    }
};

export const listHeroFooter = (country, category) => async (dispatch) => {
    try {
        dispatch({ type: HERO_FOOTER_LIST_REQUEST });
        const { data } = await axios.get(`${serverIp}/api/hero?country=${country}&category=${category}`);
        dispatch({ type: HERO_FOOTER_LIST_SUCCESS, payload: data });

    } catch (err) {
        dispatch({
            type: HERO_FOOTER_LIST_FAIL,
            payload:
                err.response && err.response.data.message
                    ? err.response.data.message
                    : err.message,
        });
    }
};

export const listHeroDetails = (id) => async (dispatch) => {
	try {
		dispatch({ type: HERO_DETAILS_REQUEST });

		const { data } = await axios.get(`${serverIp}/api/hero/${id}`);

		dispatch({ type: HERO_DETAILS_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: HERO_DETAILS_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const deleteHero = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: HERO_DELETE_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		await axios.delete(`${serverIp}/api/hero/${id}`, config);

		dispatch({ type: HERO_DELETE_SUCCESS });
	} catch (err) {
		dispatch({
			type: HERO_DELETE_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const createHero = () => async (dispatch, getState) => {
	try {
		dispatch({ type: HERO_CREATE_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.post(`${serverIp}/api/hero`, {}, config);

		dispatch({ type: HERO_CREATE_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: HERO_CREATE_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const updateHero = (hero) => async (dispatch, getState) => {
	try {
		dispatch({ type: HERO_UPDATE_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Content-Type': 'application/json',
			},
		};

		const { data } = await axios.put(
			`${serverIp}/api/hero/${hero._id}`,
			hero,
			config
		);

		dispatch({ type: HERO_UPDATE_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: HERO_UPDATE_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};