import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Icon } from "@chakra-ui/react";
import { MdDashboard, MdSafetyCheck } from "react-icons/md";
import { IoStorefrontSharp } from "react-icons/io5";
import { FaShoppingBag } from "react-icons/fa";
import {
  RiUserFill,
  RiTeamFill,
  RiLogoutBoxRLine,
  RiCoupon3Fill,
} from "react-icons/ri";
import { BiSupport, BiCarousel, BiMenuAltLeft } from "react-icons/bi";
import { RxAvatar } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import "./sidenav.css";
import { logout } from "../actions/userActions";
import { PiAddressBook } from "react-icons/pi";
import { AiOutlineUser } from "react-icons/ai";
import { useEffect } from "react";
import { toast } from "react-toastify";

const sidebar = [
  { name: "Dashboard", href: "/dashboard", icon: MdDashboard, userMenu: false },
  {
    name: "My Store",
    href: "/dashboard/mystore",
    icon: IoStorefrontSharp,
    userMenu: false,
  },
  {
    name: "Set",
    href: "/dashboard/set",
    icon: IoStorefrontSharp,
    userMenu: false,
  },
  {
    name: "Collections",
    href: "/dashboard/collections",
    icon: BiMenuAltLeft,
    userMenu: false,
  },
  {
    name: "Categories",
    href: "/dashboard/categories",
    icon: BiMenuAltLeft,
    userMenu: false,
  },
  {
    name: "Sub Categories",
    href: "/dashboard/subcategories",
    icon: BiMenuAltLeft,
    userMenu: false,
  },
  {
    name: "Styles",
    href: "/dashboard/styles",
    icon: BiMenuAltLeft,
    userMenu: false,
  },
  {
    name: "Menu",
    href: "/dashboard/menu",
    icon: BiMenuAltLeft,
    userMenu: false,
  },
  {
    name: "Order",
    href: "/dashboard/order",
    icon: FaShoppingBag,
    userMenu: false,
  },
  { name: "My Orders", href: "/orders", icon: FaShoppingBag, userMenu: true },
  {
    name: "Warranty Claims",
    href: "/claims",
    icon: MdSafetyCheck,
    userMenu: true,
  },
  {
    name: "Addresses",
    href: "/addresses",
    icon: PiAddressBook,
    userMenu: true,
  },
  { name: "Profile", href: "/profile", icon: AiOutlineUser, userMenu: true },
  {
    name: "Coupons",
    href: "/dashboard/couponCode",
    icon: RiCoupon3Fill,
    userMenu: false,
  },
  {
    name: "Customers",
    href: "/dashboard/customers",
    icon: RiUserFill,
    userMenu: false,
  },
  {
    name: "Support",
    href: "/dashboard/support",
    icon: BiSupport,
    userMenu: false,
  },
  { name: "Team", href: "/dashboard/team", icon: RiTeamFill, userMenu: false },
  {
    name: "Banner",
    href: "/dashboard/banner",
    icon: BiCarousel,
    userMenu: false,
  },
];

export default function Sidebar(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      navigate(`/signup`);
    }
  }, [userInfo]);

  const logoutHandler = () => {
    dispatch(logout());
    navigate("/");
    notify("#", "Thank you for visiting Sairah", toast.success);
  };

  const notify = (link, content, type) =>
    type(<RouterLink to={link}>{content}</RouterLink>);

  return (
    <div>
      <section id="sidebar" className="">
        <RouterLink
          to="/"
          className="flex mt-4 md:mt-0 justify-center brand animate-jump animate-infinite animate-duration-[3000ms] animate-delay-[900ms] animate-ease-in-out md:animate-none"
        >
          <span className="sr-only">Sapphire 365</span>
          <img
            className="h-12 w-auto hidden md:block"
            src="https://d8v879q3glvao.cloudfront.net/products/1730092773960.png"
            alt=""
          />
          <img
            className="h-14 w-14 object-cover object-center block md:hidden"
            src="../mstile-150x150.png"
            alt=""
          />
        </RouterLink>
        <ul className="side-menu top">
          {sidebar
            .filter((menu) => userInfo?.isAdmin || menu.userMenu)
            .map((side, index) => {
              if (side.name === "Team" && userInfo?.role === "subAdmin") {
                return null;
              }
              return (
                <li
                  key={index}
                  className={`${side.name === props.menu ? "active" : ""}`}
                >
                  <RouterLink to={side.href}>
                    <Icon as={side.icon} className="bx" />
                    <span className="hidden text md:flex">{side.name}</span>
                  </RouterLink>
                </li>
              );
            })}
        </ul>

        <ul className="side-menu">
          <li>
            <a onClick={logoutHandler} className="logout">
              <Icon as={RiLogoutBoxRLine} className="bx" />
              <span className="hidden text md:flex cursor-pointer">Logout</span>
            </a>
          </li>
        </ul>
      </section>
      <div>
        <section id="content">
          <nav>
            <a href="#" className="profile">
              <Icon as={RxAvatar} boxSize="10" />
            </a>
            <p>{userInfo?.name}</p>
          </nav>
        </section>
      </div>
    </div>
  );
}
