import React, { useState, useEffect, useRef } from "react";
import { BsSearch } from "react-icons/bs";
import "tailwindcss/tailwind.css"; // Import Tailwind CSS
import "./SearchBox.css"; // Import your custom CSS file
import { Button, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { IoIosSearch } from "react-icons/io";

const suggestions = [
  "Rings",
  "Earrings",
  "Pendants",
  "Only For You",
  "Latest Arrivals",
  "Gifting Special",
];

const SearchBox = (props) => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [PlaceHoder, setPlaceHoder] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [inputFocused, setInputFocused] = useState(false); // Track input focus
  const inputRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const currentSuggestion = suggestions[currentIndex];

  useEffect(() => {
    if (inputRef.current !== document.activeElement) {
      let currentChar = 0;
      let typingTimeout;

      const typeChar = () => {
        setPlaceHoder(currentSuggestion.slice(0, currentChar));
        currentChar++;

        if (currentChar <= currentSuggestion.length) {
          typingTimeout = setTimeout(typeChar, 100); // Adjust typing speed
        }
      };

      typeChar();

      return () => clearTimeout(typingTimeout);
    }
  }, [currentIndex]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    props.setSearchVisible(false);
    if (inputValue !== "") {
      navigate(`/search?q=${inputValue}`);
      setInputValue("");
      inputRef.current.blur();
    }
  };

  return (
    <div className="w-full">
      <form onSubmit={handleSearch}>
        <InputGroup size="md">
          <Input
            ref={inputRef}
            value={inputValue}
            placeholder={PlaceHoder}
            bgColor="white"
            onChange={handleInputChange}
            border="1px"
            rounded="full"
          />
          <InputRightElement>
            <button type="submit">
              <IoIosSearch className="h-6 w-6 flex-shrink-0 text-gray-500" />
            </button>
          </InputRightElement>
        </InputGroup>
      </form>
    </div>
  );
};

export default SearchBox;
