import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
} from "@chakra-ui/react";
import { IoCaretForwardSharp } from "react-icons/io5";
import { Link as RouterLink } from "react-router-dom";

const CheckoutSteps = ({ step1, step2, step3, step4 }) => {
  return (
    <Flex justifyContent="center" mb="8">
      <Breadcrumb separator={<IoCaretForwardSharp color="gray.500" />}>
        <BreadcrumbItem>
          {step1 ? (
            <BreadcrumbLink fontSize={{ sm: "smaller", md: "md" }}>
              Login
            </BreadcrumbLink>
          ) : (
            <BreadcrumbLink
              fontSize={{ sm: "smaller", md: "md" }}
              disabled
              color="gray.400"
            >
              Login
            </BreadcrumbLink>
          )}
        </BreadcrumbItem>

        <BreadcrumbItem>
          {step2 ? (
            <BreadcrumbLink
              fontSize={{ sm: "smaller", md: "md" }}
              as={RouterLink}
              to="/shipping"
            >
              Shipping
            </BreadcrumbLink>
          ) : (
            <BreadcrumbLink
              fontSize={{ sm: "smaller", md: "md" }}
              disabled
              color="gray.400"
            >
              Shipping
            </BreadcrumbLink>
          )}
        </BreadcrumbItem>

        <BreadcrumbItem>
          {step3 ? (
            <BreadcrumbLink
              fontSize={{ sm: "smaller", md: "md" }}
              as={RouterLink}
              to="/payment"
            >
              Payment Method
            </BreadcrumbLink>
          ) : (
            <BreadcrumbLink
              fontSize={{ sm: "smaller", md: "md" }}
              disabled
              color="gray.400"
            >
              Payment Method
            </BreadcrumbLink>
          )}
        </BreadcrumbItem>

        <BreadcrumbItem>
          {step4 ? (
            <BreadcrumbLink
              fontSize={{ sm: "smaller", md: "md" }}
              as={RouterLink}
              to="/placeorder"
            >
              Place Order
            </BreadcrumbLink>
          ) : (
            <BreadcrumbLink
              fontSize={{ sm: "smaller", md: "md" }}
              disabled
              color="gray.400"
            >
              Place Order
            </BreadcrumbLink>
          )}
        </BreadcrumbItem>
      </Breadcrumb>
    </Flex>
  );
};

export default CheckoutSteps;
