import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  createCategory,
  updateCategory,
  getCategoryDetails,
  listCatSubcategory,
  listCategory,
} from "../actions/categoryActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  CATEGORY_CREATE_RESET,
  CATEGORY_UPDATE_RESET,
} from "../constants/categoryConstants";
import {
  SUB_CATEGORY_CREATE_RESET,
  SUB_CATEGORY_UPDATE_RESET,
} from "../constants/subCategoryConstants";
import {
  createSubCategory,
  getSubCategoryDetails,
  updateSubCategory,
} from "../actions/subCategoryActions";
import { Select } from "@chakra-ui/react";
import axios from "axios";
import { serverIp } from "../config/conf";

function SubCategoryForm() {
  const { id: subCategoryId } = useParams(); // Get category ID if editing
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [thumbImage, setThumbImage] = useState("");
  const [uploading, setUploading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    loading: loadingCategory,
    error: errorCategory,
    categories: catList,
  } = useSelector((state) => state.categoryList);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // Access category creation and update states
  const subCategoryCreate = useSelector((state) => state.subCategoryCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
  } = subCategoryCreate;

  const subCategoryUpdate = useSelector((state) => state.subCategoryUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = subCategoryUpdate;

  const subCategoryDetails = useSelector((state) => state.subCategoryDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    subcategory,
  } = subCategoryDetails;

  // When editing, load the category details
  useEffect(() => {
    if (subCategoryId) {
      if (!subcategory || subcategory._id !== subCategoryId || successUpdate) {
        dispatch({ type: SUB_CATEGORY_UPDATE_RESET });
        dispatch(getSubCategoryDetails(subCategoryId));
      } else {
        setCategory(subcategory.category ? subcategory.category._id : "");
        setName(subcategory.name);
        setDescription(subcategory.description);
        setImage(subcategory.image);
        setThumbImage(subcategory.thumbImage);
      }
    }
    if (successCreate) {
      dispatch({ type: SUB_CATEGORY_CREATE_RESET });
      navigate("/dashboard/subcategories");
    } else if (successUpdate) {
      dispatch({ type: SUB_CATEGORY_UPDATE_RESET });
      navigate("/dashboard/subcategories");
    }

    if (!userInfo.isAdmin) {
      navigate("/login");
    } else {
      dispatch(listCategory());
    }
  }, [
    dispatch,
    subCategoryId,
    subcategory,
    successCreate,
    successUpdate,
    navigate,
  ]);

  // Handle form submission for both create and update
  const submitHandler = (e) => {
    e.preventDefault();

    const subCategoryData = {
      name,
      category,
      description,
      image,
      thumbImage,
    };

    if (subCategoryId) {
      dispatch(updateSubCategory({ _id: subCategoryId, ...subCategoryData })); // Update category
    } else {
      dispatch(createSubCategory(subCategoryData)); // Create category
    }
  };

  const handleCatChange = (e) => {
    setCategory(e.target.value);
  };

  const uploadImageHandler = async (e, setImageCallback) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${serverIp}/api/uploads/single`,
        formData,
        config
      );

      // Use the passed callback to set the image URL
      setImageCallback(data);

      setUploading(false);
    } catch (err) {
      console.error(err);
      setUploading(false);
    }
  };

  return (
    <div className="container mx-auto">
      <h2 className="text-2xl font-semibold mb-4">
        {subCategoryId ? "Edit Sub Category" : "Add New Sub Category"}
      </h2>

      {/* Show loader or error messages */}
      {(loadingCreate || loadingUpdate || loadingDetails) && <Loader />}
      {(errorCreate || errorUpdate || errorDetails) && (
        <Message type="error">
          {errorCreate || errorUpdate || errorDetails}
        </Message>
      )}
      {successUpdate && (
        <Message type="success">Sub Category Updated Successfully!</Message>
      )}
      {successCreate && (
        <Message type="success">Sub Category Created Successfully!</Message>
      )}

      <form onSubmit={submitHandler}>
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700">
            Sub Category Name
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>

        {loadingCategory ? (
          <Loader />
        ) : errorCategory ? (
          <Message type="error">{errorCategory}</Message>
        ) : (
          <div className="mb-4">
            <label
              htmlFor="category"
              className="block font-semibold text-base mb-1"
            >
              Category
            </label>
            <Select
              id="category"
              value={category}
              name="category"
              onChange={(e) => handleCatChange(e)}
              required
              className="border-black"
            >
              <option value="null">Select Category</option>
              {catList.map((menu, idx) => (
                <option key={idx} value={menu._id}>
                  {menu.name}
                </option>
              ))}
            </Select>
          </div>
        )}

        <div className="mb-4">
          <label htmlFor="description" className="block text-gray-700">
            Description
          </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            rows="4"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="image" className="block text-gray-700">
            Image URL
          </label>
          <input
            type="file"
            id="image"
            onChange={(e) => uploadImageHandler(e, setImage)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="thumbImage" className="block text-gray-700">
            Thumbnail Image URL
          </label>
          <input
            type="file"
            id="thumbImage"
            onChange={(e) => uploadImageHandler(e, setThumbImage)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700 transition"
        >
          {subCategoryId ? "Update Category" : "Add Category"}
        </button>
      </form>
    </div>
  );
}

export default SubCategoryForm;
