import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import OtpInput from "otp-input-react";
import "react-phone-number-input/style.css";

import { auth } from "../config/firebase.config";
import Message from "../components/Message";
import {
  USER_OTP_VALIDATE_RESET,
  USER_LOGOUT,
} from "../constants/userConstants";
import { scrollToTop } from "../components/ScrollToTop";
import { login } from "../actions/userActions";
import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import axios from "axios";
import { serverIp } from "../config/conf";
import { toast } from "react-toastify";

export default function Signup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let redirect = searchParams.get("redirect") || "/";

  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [otpLoading, setOtpLoading] = useState(false);
  const [otpVerifying, setOtpVerifying] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [error, setError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { error: loginError, userInfo } = userLogin;

  const userOtpValidate = useSelector((state) => state.userOtpValidate);
  const { success } = userOtpValidate;

  useEffect(() => {
    scrollToTop();
    if (userInfo && userInfo.successOTP && !success) {
      navigate("/otp?type=signup");
      dispatch({ type: USER_LOGOUT });
    } else if (userInfo && userInfo._id) {
      navigate(redirect);
      notify("#", "Welcome to Sairah", toast.success);
    }
    dispatch({ type: USER_OTP_VALIDATE_RESET });
  }, [userInfo, redirect]);

  const notify = (link, content, type) =>
    type(<Link to={link}>{content}</Link>);

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(phoneNumber)) {
      setPhoneError("Please enter a valid 10-digit phone number.");
      return false;
    }
    setPhoneError("");
    return true;
  };

  const handleError = (message) => {
    setError(message);
    setTimeout(() => setError(""), 3000); // Clear error after 3 seconds
  };

  const sendOtp = async (e) => {
    e.preventDefault();
    setOtpLoading(true);
    if (!validatePhoneNumber(phone)) {
      return;
    }

    try {
      const { data } = await axios.post(`${serverIp}/api/users/userExist`, {
        phone: `+91${phone}`,
      });

      if (data.success) {
        if (!window.recaptchaVerifier) {
          window.recaptchaVerifier = new RecaptchaVerifier(
            "recaptcha-container",
            {
              size: "invisible",
              callback: (response) => {
                console.log("Recaptcha verified");
              },
              "expired-callback": () => {
                console.error("Recaptcha expired");
              },
            },
            auth
          );
        }

        signInWithPhoneNumber(auth, `+91${phone}`, window.recaptchaVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            setOtpSent(true);
            setOtpLoading(false);
            handleError("");
          })
          .catch((err) => handleError("Error sending OTP: " + err.message));
      } else {
        handleError("Please register this phone number.");
      }
    } catch (error) {
      handleError("Account Does not Exist.");
    }
  };

  const verifyOtp = (e) => {
    e.preventDefault();
    setOtpVerifying(true);
    if (!otp || otp.length !== 6) {
      handleError("Please enter a valid 6-digit OTP.");
      return;
    }

    window.confirmationResult
      .confirm(otp)
      .then((result) => {
        setOtpVerified(true);
        setOtpVerifying(false);
        document.getElementById("recaptcha-container").style.display = "none";
        const user = result.user;
        user.getIdToken().then((idToken) => {
          dispatch(login(`+91${phone}`, idToken, user.uid));
        });
      })
      .catch((err) => handleError("Invalid OTP: " + err.message));
  };

  return (
    <div className="flex flex-col md:flex-row justify-between gap-10 px-4 lg:px-8 py-4 bg-white">
      <div className="flex flex-col items-center w-full md:w-1/2 p-4 md:p-10 space-y-8">
        <div className="flex flex-col w-full md:w-[90%]">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900 mb-3">
            Sign In
          </h2>
          <h3 className="font-semibold text-[14px]">
            Please Sign In to your SAIRAH Account using OTP.
          </h3>
        </div>
        <form
          className="mt-8 space-y-6 w-full md:w-[90%]"
          onSubmit={otpSent ? (e) => e.preventDefault() : sendOtp}
        >
          <div className="flex flex-col gap-4 -space-y-px rounded-md">
            <FormControl isInvalid={phoneError}>
              <InputGroup>
                <InputLeftAddon px="4" py="5">
                  +91
                </InputLeftAddon>
                <Input
                  type="tel"
                  placeholder="Phone"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                    validatePhoneNumber(e.target.value);
                  }}
                  // onBlur={validatePhoneNumber}
                  maxLength={10}
                  width="full"
                  px="4"
                  py="5"
                  border="1px"
                  borderColor="gray.300"
                  rounded="md"
                  required
                />
              </InputGroup>
              {phoneError && <FormErrorMessage>{phoneError}</FormErrorMessage>}
            </FormControl>
            <Button
              type="submit"
              // onClick={sendOtp}
              bgColor="#000080"
              _hover={{ bgColor: "#000090" }}
              color="white"
              w="full"
              py="2"
              isLoading={otpLoading}
              loadingText="Loading..."
              fontSize="sm"
              isDisabled={phoneError || !phone}
            >
              {otpSent ? "Resend OTP" : "Send OTP"}
            </Button>
            {otpSent && (
              <>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  OTPLength={6}
                  autoFocus
                  otpType="number"
                  inputStyles={{
                    width: "100%", // Allows input width to fill container in mobile view
                    maxWidth: "40px", // Sets a maximum width to prevent overflow on larger screens
                    margin: "0 4px", // Simplifies margin for better spacing
                    fontSize: "16px", // Slightly larger for readability
                    textAlign: "center",
                    padding: "4px", // Minimal padding to prevent text clipping
                    borderRadius: "6px",
                    border: "1px solid #ccc",
                  }}
                  className="opt-container flex w-full justify-center px-2 py-2"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      verifyOtp(e);
                    }
                  }}
                />
                <Button
                  type="button"
                  onClick={verifyOtp}
                  bgColor="#000080"
                  _hover={{ bgColor: "#000090" }}
                  color="white"
                  isLoading={otpVerifying}
                  loadingText="Loading..."
                  w="full"
                  py="2"
                  fontSize="sm"
                >
                  Verify OTP
                </Button>
              </>
            )}
          </div>
          {loginError && <Message type="error">{loginError}</Message>}
        </form>
        {error && <Message type="error">{error}</Message>}
      </div>
      <div className="flex justify-center items-center">
        <div className="border-b-2 md:border-b-0 md:border-l-2 border-gray-300 w-full h-0 md:w-2 md:h-[300px]"></div>
      </div>
      <div className="flex items-center flex-col w-full md:w-1/2 p-4 md:p-10 space-y-8">
        <div className="flex flex-col gap-4 md:gap-10 w-full md:w-[90%] mb-4">
          <Link
            to="/registration"
            className="text-2xl font-bold tracking-tight text-gray-900 mb-3"
          >
            Create an Account
          </Link>
          <h3 className="font-semibold text-[14px] leading-10">
            Save time during checkout, view your shopping bag and saved items
            from any device and access your order history.
          </h3>
        </div>
        <div className="flex items-center w-full md:w-[90%]">
          <Link
            to="/registration"
            className="flex w-full justify-center rounded-md bg-[#000080] px-3 py-2 text-sm font-semibold text-white hover:bg-[#000090] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Register
          </Link>
        </div>
      </div>
    </div>
  );
}
