import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  createCategory,
  updateCategory,
  getCategoryDetails,
} from "../actions/categoryActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  STYLE_CREATE_RESET,
  STYLE_UPDATE_RESET,
} from "../constants/stylesConstants";
import { createStyle, getStyleDetails, updateStyle } from "../actions/stylesActions";

function StylesForm() {
  const { id: styleId } = useParams(); // Get category ID if editing
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [thumbImage, setThumbImage] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Access category creation and update states
  const styleCreate = useSelector((state) => state.styleCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
  } = styleCreate;

  const styleUpdate = useSelector((state) => state.styleUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = styleUpdate;

  const styleDetails = useSelector((state) => state.styleDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    style,
  } = styleDetails;

  // When editing, load the category details
  useEffect(() => {
    if (styleId) {
      if (!style || style._id !== styleId || successUpdate) {
        dispatch({ type: STYLE_UPDATE_RESET });
        dispatch(getStyleDetails(styleId));
      } else {
        setName(style.name);
        setDescription(style.description);
        setImage(style.image);
        setThumbImage(style.thumbImage);
      }
    }
    if (successCreate) {
      dispatch({ type: STYLE_CREATE_RESET });
      navigate("/dashboard/styles");
    } else if (successUpdate) {
      dispatch({ type: STYLE_UPDATE_RESET });
      navigate("/dashboard/styles");
    }
  }, [dispatch, styleId, style, successCreate, successUpdate, navigate]);

  // Handle form submission for both create and update
  const submitHandler = (e) => {
    e.preventDefault();

    const styleData = {
      name,
      description,
      image,
      thumbImage,
    };

    if (styleId) {
      dispatch(updateStyle({ _id: styleId, ...styleData })); // Update category
    } else {
      dispatch(createStyle(styleData)); // Create category
    }
  };

  return (
    <div className="container mx-auto">
      <h2 className="text-2xl font-semibold mb-4">
        {styleId ? "Edit Style" : "Add New Style"}
      </h2>

      {/* Show loader or error messages */}
      {(loadingCreate || loadingUpdate || loadingDetails) && <Loader />}
      {(errorCreate || errorUpdate || errorDetails) && (
        <Message type="error">
          {errorCreate || errorUpdate || errorDetails}
        </Message>
      )}
      {successUpdate && (
        <Message type="success">Style Updated Successfully!</Message>
      )}
      {successCreate && (
        <Message type="success">Style Created Successfully!</Message>
      )}

      <form onSubmit={submitHandler}>
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700">
            Style Name
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="description" className="block text-gray-700">
            Description
          </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            rows="4"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="image" className="block text-gray-700">
            Image URL
          </label>
          <input
            type="text"
            id="image"
            value={image}
            onChange={(e) => setImage(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="thumbImage" className="block text-gray-700">
            Thumbnail Image URL
          </label>
          <input
            type="text"
            id="thumbImage"
            value={thumbImage}
            onChange={(e) => setThumbImage(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700 transition"
        >
          {styleId ? "Update Style" : "Add Style"}
        </button>
      </form>
    </div>
  );
}

export default StylesForm;
