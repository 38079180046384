import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Icon,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { MdOutlineEmail, MdSupportAgent } from "react-icons/md";
import { HiOutlineChatBubbleBottomCenterText } from "react-icons/hi2";
import { IoCallOutline } from "react-icons/io5";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listFaqs } from "../actions/supportFaqActions";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useState } from "react";
import axios from "axios";
import { serverIp } from "../config/conf";
import { isValidPhoneNumber } from "react-phone-number-input";
import { scrollToTop } from "../components/ScrollToTop";

export default function Support() {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [query, setQuery] = useState("");
  const [err, setErr] = useState("");

  const faqList = useSelector((state) => state.faqList);
  const { loading, error, faqs } = faqList;

  const supportDetails = useSelector((state) => state.supportDetails);
  const {
    loading: loadingSupport,
    error: errorSupport,
    support,
  } = supportDetails;

  useEffect(() => {
    scrollToTop();
    dispatch(listFaqs());
  }, [dispatch]);

  const uniqueCategories = faqs?.reduce((categories, faq) => {
    const category = faq.category;
    if (!categories.includes(category)) {
      categories.push(category);
    }
    return categories;
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    onClose();
    try {
      if (phone) {
        if (isValidPhoneNumber(phone)) {
          const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };

          const { data } = await axios.post(
            `${serverIp}/api/users/callback`,
            { name, email, phone, orderNumber, query },
            config
          );
          if (data) {
            alert(
              "Thank You for placing callback request. An authorized Sapphire365 representative shall get in touch with you soon on the contact number provided."
            );
            setName("");
            setPhone("");
            setEmail("");
            setOrderNumber("");
            setQuery("");
          }
        }
      } else {
        throw new Error("Phone number required");
      }
    } catch (err) {
      setErr(err.message);
    }
  };

  return (
    <div className="flex flex-col bg-white">
      <div className="flex flex-col gap-8 py-6 border border-b-gray-400">
        <div className="self-center text-2xl font-bold font-delius underline underline-offset-8">
          Support
        </div>
        <div className="self-center text-lg text-gray-400 font-bold font-delius text-center">
          Need more help? Our support team is here to help you.
        </div>

        <Modal size="xl" isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Request a Callback</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <form
                className="bg-white p-4 rounded-lg shadow-md"
                onSubmit={handleSubmit}
              >
                <div className="mb-4">
                  <label htmlFor="name" className="flex">
                    <p>Name</p>
                    <p className="text-red-500">*</p>
                  </label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    pattern="[A-Za-z ]+"
                    title="Please enter only alphabetic characters"
                    autoComplete="name"
                    required
                    className="relative block w-full"
                    placeholder=" Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="phone" className="flex">
                    <p>Phone</p>
                    <p className="text-red-500">*</p>
                  </label>
                  <div className="flex w-full">
                    <PhoneInput
                      className="w-full rounded-tr-md"
                      defaultCountry="IN"
                      international
                      countryCallingCodeEditable={false}
                      placeholder="Enter phone number"
                      countries={["IN", "US", "GB", "CA", "AE"]}
                      value={phone}
                      onChange={setPhone}
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <label htmlFor="email-address" className="flex">
                    <p>Email address</p>
                    <p className="text-red-500">*</p>
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="relative block w-full"
                    placeholder=" Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="order" className="flex">
                    <p>Order number</p>
                  </label>
                  <input
                    id="order"
                    name="order"
                    type="number"
                    autoComplete="order"
                    className="relative block w-full"
                    placeholder=" Order number"
                    value={orderNumber}
                    onChange={(e) => setOrderNumber(e.target.value)}
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="query" className="flex">
                    <p>Query</p>
                    <p className="text-red-500">*</p>
                  </label>
                  <textarea
                    id="query"
                    name="query"
                    value={query}
                    required
                    onChange={(e) => setQuery(e.target.value)}
                    className="border w-full px-3 py-2 mt-1 focus:outline-none focus:ring focus:border-blue-300"
                  ></textarea>
                </div>

                {err && err !== "" && <p className="text-red-500">{err}</p>}

                <div className="flex items-center justify-center">
                  <button
                    onClick={onClose}
                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mr-2"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="bg-[#000090] hover:bg-[#000080] text-white font-bold py-2 px-4 rounded"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </ModalBody>
          </ModalContent>
        </Modal>

        {loadingSupport ? (
          <div>Loading</div>
        ) : errorSupport ? (
          <div>Error</div>
        ) : (
          <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4 gap-y-20">
            <a
              className="flex flex-col justify-center items-center gap-2 text-lg font-bold font-delius"
              href={`mailto:${support.mail}`}
            >
              <Icon as={MdOutlineEmail} boxSize="10" />
              <p className="text-lg font-bold">Email Us</p>({support.mail})
              <p className="text-lg font-bold">Replies within 24 hours</p>
            </a>
            <a
              className="flex flex-col justify-center items-center gap-2 text-lg font-bold font-delius"
              href={`tel:${support.call}`}
            >
              <Icon as={IoCallOutline} boxSize="10" />
              <p className="text-lg font-bold">Call us</p>({support.call})
              <p className="text-lg font-bold">
                Open 11:00 am IST to 06:00 pm IST
              </p>
            </a>
            <a
              className="flex flex-col justify-center items-center gap-2"
              href="https://api.whatsapp.com/send/?phone=+918828826996&text=Hello+Sairah.&type=phone_number&app_absent=0"
              target="_blank"
            >
              <Icon as={HiOutlineChatBubbleBottomCenterText} boxSize="10" />
              <p className="text-lg font-bold">Chat with us</p>
            </a>
            <button
              onClick={onOpen}
              className="flex flex-col justify-center items-center gap-2"
            >
              <Icon as={MdSupportAgent} boxSize="10" />
              <p className="text-lg font-bold">Request a Callback</p>
            </button>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-8 py-6 border-b">
        <div className="self-center text-2xl font-bold font-delius underline underline-offset-8">
          FAQs
        </div>
        <div className="self-center text-lg text-gray-400 font-bold font-delius text-center">
          Frequently Asked Questions
        </div>
        <div>
          {loading ? (
            <div>Loading</div>
          ) : error ? (
            <div>Error</div>
          ) : (
            <div className="flex flex-col px-6">
              {uniqueCategories.map((cat) => (
                <Accordion allowMultiple pb="4">
                  <p className="text-lg font-bold">{cat}</p>
                  {faqs
                    .filter((faq) => faq.category === cat)
                    .map((faq, index) => (
                      <AccordionItem pb="2">
                        <h2>
                          <AccordionButton bg="#dddddd">
                            <Box as="span" flex="1" textAlign="left">
                              {faq.question}
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>{faq.answer}</AccordionPanel>
                      </AccordionItem>
                    ))}
                </Accordion>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
