import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  listProductSets,
  deleteProductSet,
  createProductSet,
} from "../actions/productSetAction";
import { PRODUCT_SET_CREATE_RESET } from "../constants/productSetConstants";
import Sidebar from "../components/Sidebar";

const ProductSetList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productSetList = useSelector((state) => state.productSetList);
  const { loading, error, productSets } = productSetList;

  const productSetDelete = useSelector((state) => state.productSetDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productSetDelete;

  const productSetCreate = useSelector((state) => state.productSetCreate);
  const { success: successCreate, productSet: createdProductSet } =
    productSetCreate;

  useEffect(() => {
    if (!userInfo.isAdmin) {
      navigate("/login");
    }
    if (successCreate) {
      dispatch({ type: PRODUCT_SET_CREATE_RESET });
      navigate(`/dashboard/product-set/${createdProductSet._id}/edit`);
    } else {
      dispatch(listProductSets());
    }
  }, [dispatch, navigate, successDelete, successCreate, createdProductSet]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure you want to delete this product set?")) {
      dispatch(deleteProductSet(id));
    }
  };

  const createHandler = () => {
    dispatch(
      createProductSet({
        name: "New Product Set",
        image: "image",
        products: [],
      })
    );
  };

  return (
    <div>
      <Sidebar menu="Set" />
      <section id="content">
        <main>
          <div className="container bg-white mx-auto px-4 py-6">
            <div className="flex justify-between items-center mb-6">
              <h1 className="text-3xl font-bold">Product Sets</h1>
              <button
                onClick={createHandler}
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
              >
                Add Product Set
              </button>
            </div>

            {loadingDelete && <p className="text-blue-500">Deleting...</p>}
            {errorDelete && <p className="text-red-500">{errorDelete}</p>}

            {loading ? (
              <p className="text-blue-500">Loading...</p>
            ) : error ? (
              <p className="text-red-500">{error}</p>
            ) : (
              <table className="min-w-full bg-white border border-gray-200">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="px-4 py-2 text-left border">ID</th>
                    <th className="px-4 py-2 text-left border">Name</th>
                    <th className="px-4 py-2 text-left border">Image</th>
                    <th className="px-4 py-2 text-left border">Products</th>
                    <th className="px-4 py-2 text-left border">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {productSets.map((productSet) => (
                    <tr key={productSet._id}>
                      <td className="px-4 py-2 border">{productSet._id}</td>
                      <td className="px-4 py-2 border">{productSet.name}</td>
                      <td className="px-4 py-2 border">
                        <img
                          src={productSet.image}
                          alt={productSet.name}
                          className="w-12 h-12 object-cover"
                        />
                      </td>
                      <td className="px-4 py-2 border">
                        <div className="flex max-w-[240px] overflow-x-auto space-x-2">
                          {productSet.products.map((product, index) => (
                            <img
                              key={index}
                              src={
                                product?.variants?.filter(
                                  (prod) => prod.isPrimary
                                )[0]?.images[0]
                              } // Assuming product has an `image` field
                              alt={product.name} // Assuming product has a `name` field
                              className="w-20 h-20 object-cover"
                            />
                          ))}
                        </div>
                      </td>
                      <td className="px-4 py-2 border">
                        <div className="flex space-x-2">
                          <Link
                            to={`/dashboard/product-set/${productSet._id}/edit`}
                            className="bg-green-500 text-white py-1 px-3 rounded hover:bg-green-600"
                          >
                            Edit
                          </Link>
                          <button
                            onClick={() => deleteHandler(productSet._id)}
                            className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </main>
      </section>
    </div>
  );
};

export default ProductSetList;
