import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import { createOrder } from "../actions/orderActions";
import CheckoutSteps from "../components/CheckoutSteps";
import {
  ORDER_CREATE_RESET,
  ORDER_DETAILS_RESET,
} from "../constants/orderConstants";
import { CART_CLEAR_ITEMS } from "../constants/cartConstants";
import { scrollToTop } from "../components/ScrollToTop";
import { useState } from "react";
import { checkCoupon } from "../actions/couponActions";
import Message from "../components/Message";
import Loader from "../components/Loader";

const PlaceOrderScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart);

  const [note, setNote] = useState("");
  const [giftWrap, setGiftWrap] = useState("");
  const [code, setCode] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const couponValidation = useSelector((state) => state.couponValidation);
  const { loading, error: errorCoupon, isValid, discount } = couponValidation;

  cart.itemsPrice = cart.cartItems.reduce(
    (acc, currVal) => acc + currVal.price * +currVal.qty,
    0
  );
  cart.shippingPrice = 0; //cart.itemsPrice < 1000 ? 1000 : 0;
  cart.taxPrice = 0; //(18 * cart.itemsPrice) / 100;
  cart.discount = cart.itemsPrice * (discount / 100);
  cart.totalPrice = (
    cart.itemsPrice +
    cart.shippingPrice +
    cart.taxPrice -
    cart.discount
  ).toFixed(0);

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success } = orderCreate;

  const placeOrderHandler = () => {
    dispatch(
      createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        paymentMethod: cart.paymentMethod,
        itemsPrice: cart.itemsPrice,
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice,
        couponCode: cart.couponCode,
        discount: discount,
      })
    );
  };

  useEffect(() => {
    scrollToTop();
    if (success) {
      navigate(`/order/${order._id}`);
      dispatch({ type: ORDER_CREATE_RESET });
      dispatch({ type: CART_CLEAR_ITEMS });
      dispatch({ type: ORDER_DETAILS_RESET });
    }
  }, [success, navigate, order]);

  const checkCouponHandler = (e) => {
    e.preventDefault();
    dispatch(checkCoupon(code));
    cart.couponCode = code;
  };

  return (
    <Flex>
      <Flex w="full" direction="column" p={{ sm: "3", md: "10" }}>
        <Grid
          templateColumns={{ sm: "1fr", md: "3fr 2fr" }}
          gap={{ sm: "8", md: "20" }}
        >
          <Flex direction="column">
            <Box borderBottom="1px" pb="6" borderColor="gray.300">
              <Heading as="h2" mb="3" fontSize="2xl" fontWeight="semibold">
                Shipping
              </Heading>
              <Text>
                <strong>Address: </strong>
                {cart.shippingAddress.address}, {cart.shippingAddress.city},{" "}
                {cart.shippingAddress.postalCode},{" "}
                {cart.shippingAddress.country}
              </Text>
            </Box>
            <Box borderBottom="1px" py="6" borderColor="gray.300">
              <Heading as="h2" mb="3" fontSize="2xl" fontWeight="semibold">
                Payment Method
              </Heading>
              <Text>
                <strong>Method: </strong>
                {cart.paymentMethod.toUpperCase()}
              </Text>
            </Box>
            <Box borderBottom="1px" py="6" borderColor="gray.300">
              <Heading as="h2" mb="3" fontSize="2xl" fontWeight="semibold">
                Order Items
              </Heading>
              <Box>
                {cart.cartItems.length === 0 ? (
                  <div>Your cart is empty</div>
                ) : (
                  <Box py="2">
                    {cart.cartItems.map((item, idx) => (
                      <Flex
                        key={idx}
                        direction={{ sm: "column", md: "row" }}
                        alignItems={{ sm: "flex-start", md: "center" }}
                        justifyContent={{
                          sm: "flex-start",
                          md: "space-between",
                        }}
                      >
                        <Flex py="2" alignItems="center">
                          <Image
                            src={item.image}
                            alt={item.name}
                            w="12"
                            h="12"
                            objectFit="cover"
                            mr="6"
                          />
                          <Link
                            fontWeight="bold"
                            fontSize="xl"
                            as={RouterLink}
                            to={`/products/${item.product}`}
                          >
                            {item.name}
                          </Link>
                        </Flex>

                        <Text fontSize="lg" fontWeight="semibold">
                          {item.qty} x ₹{item.price} = ₹{+item.qty * item.price}
                        </Text>
                      </Flex>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
          </Flex>

          <Flex
            direction="column"
            bgColor="white"
            justifyContent="space-between"
            p={{ sm: "2", md: "8" }}
            shadow="md"
            rounded="lg"
            borderColor="gray.300"
          >
            <Box>
              <Heading mb="6" as="h2" fontSize="3xl" fontWeight="bold">
                Order Summary
              </Heading>

              {/* Items Price */}
              <div className="flex justify-between py-2">
                <p className="text-gray-700">Items</p>
                <p className="text-gray-700">₹{cart.itemsPrice}</p>
              </div>

              {/* Shipping Price */}
              <div className="flex justify-between py-2">
                <p className="text-gray-700">Shipping</p>
                <p className="text-gray-700">₹{cart.shippingPrice}</p>
              </div>

              {/* Tax Price */}
              <div className="flex justify-between py-2">
                <p className="text-gray-700">Tax</p>
                <p className="text-gray-700">₹{cart.taxPrice}</p>
              </div>

              <div className="flex justify-between py-2">
                <p className="text-gray-700">Discount({discount}%)</p>
                <p className="text-gray-700">₹{cart.discount?.toFixed(0)}</p>
              </div>

              {isValid && (
                <div className="flex justify-between py-2">
                  <p className="text-gray-700">Coupon Code</p>
                  <p className="text-gray-700">{cart.couponCode}</p>
                </div>
              )}

              {/* Total Price */}
              <Flex
                borderY="1px"
                py="2"
                borderColor="gray.200"
                alignitems="center"
                justifyContent="space-between"
              >
                <Text fontSize="xl">Total</Text>
                <Text fontWeight="bold" fontSize="xl">
                  ₹{cart.totalPrice}
                </Text>
              </Flex>
            </Box>

            {userInfo && (
              <div className="my-8">
                <form onSubmit={checkCouponHandler}>
                  <p className="text-lg">Coupon Code</p>
                  <div className="flex gap-2 w-full">
                    <input
                      type="text"
                      id="code"
                      required
                      className="w-2/3 border border-black rounded px-3 py-2"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                    <button
                      type="submit"
                      className="w-1/3 bg-blue-500 hover:bg-blue-600 text-white font-medium px-4 py-2 rounded"
                    >
                      Apply
                    </button>
                  </div>
                </form>
                {loading && <Loader />}
                {errorCoupon && <Message type="error">{errorCoupon}</Message>}
              </div>
            )}

            <Button
              size="lg"
              textTransform="uppercase"
              colorScheme="yellow"
              type="button"
              w="full"
              onClick={placeOrderHandler}
              disabled={cart.cartItems === 0}
            >
              Place Order
            </Button>
          </Flex>
        </Grid>
      </Flex>
    </Flex>
  );
};

export default PlaceOrderScreen;
