import { Link as RouterLink } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { deleteCategory, listCategory } from "../actions/categoryActions";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";

function Categories() {
  const dispatch = useDispatch();

  const { loading, error, categories } = useSelector(
    (state) => state.categoryList
  );

  const {
    loading: loadingDelete,
    error: errorDelete,
    success,
  } = useSelector((state) => state.categoryDelete);

  useEffect(() => {
    dispatch(listCategory());
  }, [dispatch]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteCategory(id));
    }
  };

  return (
    <div>
      <Sidebar menu="Categories" />
      <section id="content">
        <main>
          <div className="font-semibold my-6">
            Add, View, Update and Delete categories....
            {/* Add Category Button */}
            <RouterLink
              to="/dashboard/categories/create"
              className="ml-4 p-2 bg-blue-500 text-white rounded"
            >
              Add Category
            </RouterLink>
          </div>
          <div className="grid gap-x-3 gap-y-3 md:gap-y-6 grid-cols-2 lg:grid-cols-6 xl:gap-x-6">
            {loading ? (
              <Loader />
            ) : error ? (
              <Message type="error">{error}</Message>
            ) : (
              categories.map((menu, indx) => (
                <div className="flex flex-col rounded-lg p-4 bg-white drop-shadow-sm hover:scale-105 transition-all hover:drop-shadow-md group">
                  <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                    <img
                      src={menu.image}
                      alt={menu.name}
                      className="h-full w-full object-cover object-center group-hover:opacity-75"
                    />
                  </div>
                  <p className="mt-4 text-center text-lg font-medium text-gray-900">
                    {menu.name}
                  </p>

                  {/* Update and Delete buttons */}
                  <div className="flex justify-around mt-4">
                    <RouterLink 
                      to={`/dashboard/categories/${menu._id}/edit`} 
                      className="p-2 bg-yellow-500 text-white rounded"
                    >
                      <CiEdit /> 
                    </RouterLink>
                    <button
                      className="p-2 bg-red-500 text-white rounded"
                      onClick={() => deleteHandler(menu._id)}
                    >
                      <RiDeleteBin6Line />
                    </button>
                  </div>
                </div>
              ))
            )}
          </div>
        </main>
      </section>
    </div>
  );
}

export default Categories;
