import { LockClosedIcon } from "@heroicons/react/20/solid";
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";

import { forgotPassword as forgotpass } from "../actions/userActions";
import { Input } from "@chakra-ui/react";
import { scrollToTop } from "../components/ScrollToTop";
import Message from "../components/Message";

export default function ForgotPass() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  let redirect = searchParams.get("redirect") || "/";

  const [email, setEmail] = useState("");

  const forgotPassword = useSelector((state) => state.forgotPassword);
  const { successOTP, error } = forgotPassword;

  useEffect(() => {
    scrollToTop();
    if (successOTP) {
      navigate("/otp?type=forgotpass");
    }
  }, [redirect, successOTP]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(forgotpass(email));
  };

  return (
    <div className="flex min-h-full items-center justify-center px-4 py-4 border-b-2 border-white sm:px-6 lg:px-8 bg-white">
      <div className="w-full max-w-md space-y-8">
        <div>
          <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-gray-800">
            Enter your email address to receive OTP
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={submitHandler}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="flex flex-col gap-4 -space-y-px rounded-md shadow-sm">
            <div>
              <label htmlFor="email-address" className="text-white">
                Email
              </label>
              <Input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                bg="white"
                placeholder=" Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div className="flex justify-center items-center">
            <button
              type="submit"
              className="group relative flex w-1/2 justify-center rounded-md bg-[#8e295b] px-3 py-2 text-sm font-semibold text-white hover:bg-[#6b1d45] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <LockClosedIcon
                  className="h-5 w-5 text-white"
                  aria-hidden="true"
                />
              </span>
              Send
            </button>
          </div>
          <div className="flex justify-center text-sm">
            <RouterLink
              to="/registration"
              className="font-medium text-[#8e295b] hover:text-[#6b1d45]"
            >
              New User? Register
            </RouterLink>
          </div>
        </form>
        {error && <Message type="error">{error}</Message>}
      </div>
    </div>
  );
}
