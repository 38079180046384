import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';
import './index.css';
import App from './App';
import store from './store';
import "./i18n";

const breakpoints = {
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px'
}

const theme = extendTheme({
  fonts: {
    heading: `'Montserrat'`,
    body: `'Montserrat'`,
  },
  breakpoints
});

// Initialize Facebook Pixel with your pixel ID
const pixelId = '1327632928208462';  // Replace with your actual Pixel ID
ReactPixel.init(pixelId, null, {
  autoConfig: true,  // Enable automatic configuration of Pixel
  debug: true,       // Enable debug mode for more detailed logging
});
ReactPixel.pageView();
ReactPixel.revokeConsent();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </Provider>
  </React.StrictMode>
);
