export const HERO_LIST_REQUEST = 'HERO_LIST_REQUEST';
export const HERO_LIST_SUCCESS = 'HERO_LIST_SUCCESS';
export const HERO_LIST_FAIL = 'HERO_LIST_FAIL';

export const HERO_LATEST_LIST_REQUEST = 'HERO_LATEST_LIST_REQUEST';
export const HERO_LATEST_LIST_SUCCESS = 'HERO_LATEST_LIST_SUCCESS';
export const HERO_LATEST_LIST_FAIL = 'HERO_LATEST_LIST_FAIL';

export const HERO_COLLECTION_LIST_REQUEST = 'HERO_COLLECTION_LIST_REQUEST';
export const HERO_COLLECTION_LIST_SUCCESS = 'HERO_COLLECTION_LIST_SUCCESS';
export const HERO_COLLECTION_LIST_FAIL = 'HERO_COLLECTION_LIST_FAIL';

export const HERO_FOOTER_LIST_REQUEST = 'HERO_FOOTER_LIST_REQUEST';
export const HERO_FOOTER_LIST_SUCCESS = 'HERO_FOOTER_LIST_SUCCESS';
export const HERO_FOOTER_LIST_FAIL = 'HERO_FOOTER_LIST_FAIL';

export const HERO_DETAILS_REQUEST = 'HERO_DETAILS_REQUEST';
export const HERO_DETAILS_SUCCESS = 'HERO_DETAILS_SUCCESS';
export const HERO_DETAILS_FAIL = 'HERO_DETAILS_FAIL';

export const HERO_DELETE_REQUEST = 'HERO_DELETE_REQUEST';
export const HERO_DELETE_SUCCESS = 'HERO_DELETE_SUCCESS';
export const HERO_DELETE_FAIL = 'HERO_DELETE_FAIL';

export const HERO_CREATE_REQUEST = 'HERO_CREATE_REQUEST';
export const HERO_CREATE_SUCCESS = 'HERO_CREATE_SUCCESS';
export const HERO_CREATE_FAIL = 'HERO_CREATE_FAIL';
export const HERO_CREATE_RESET = 'HERO_CREATE_RESET';

export const HERO_UPDATE_REQUEST = 'HERO_UPDATE_REQUEST';
export const HERO_UPDATE_SUCCESS = 'HERO_UPDATE_SUCCESS';
export const HERO_UPDATE_FAIL = 'HERO_UPDATE_FAIL';
export const HERO_UPDATE_RESET = 'HERO_UPDATE_RESET';