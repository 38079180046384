import {
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useDisclosure,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionPanel,
  AccordionIcon,
  Icon,
  RadioGroup,
  Stack,
  Radio,
  Text,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import ReactPixel from "react-facebook-pixel";
import {
  useParams,
  useLocation,
  Link as RouterLink,
  useNavigate,
} from "react-router-dom";
import { Select } from "@chakra-ui/react";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import Features from "../components/Features";
import { PRODUCT_REVIEW_CREATE_RESET } from "../constants/productConstants";
import {
  listProductDetails,
  createProductReview,
  relatedListProducts,
  ymalListProducts,
} from "../actions/productActions";
import { addItemToCart } from "../actions/cartActions";
import {
  addItemToWishlist,
  getWishlistItems,
  removeItemFromWishlist,
} from "../actions/wishListActions";
import { toast } from "react-toastify";
import ImageMagnifier from "../components/ImageMagnifier";
import ProductImageSlider from "../components/ProductImageSlider";
import "../components/productImageSlider.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PromoProductCard from "../components/PromoProductList";
import { serverIp } from "../config/conf";
import axios from "axios";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { Helmet } from "react-helmet";
import { Dialog, Transition } from "@headlessui/react";
import { FaRegEdit } from "react-icons/fa";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { listSizes } from "../actions/sizeActions";
import ProductImages from "../components/ProductImages";
import { MdOutlineShare } from "react-icons/md";
import ShareButton from "../components/ShareButton";

const getConfigurableProps = () => ({
  showArrows: true,
  showStatus: false,
  showIndicators: true,
  infiniteLoop: true,
  showThumbs: false,
  useKeyboardArrows: true,
  autoPlay: false,
  stopOnHover: true,
  swipeable: true,
  dynamicHeight: true,
  emulateTouch: true,
  autoFocus: false,
  thumbWidth: 60,
  selectedItem: 0,
  interval: 2000,
  transitionTime: 800,
  swipeScrollTolerance: 5,
});

const ColorButton = ({ color, selected, onClick, img, alt }) => {
  const buttonClasses = `w-8 h-8 md:w-6 md:h-6 rounded-full ${
    selected ? "border-[3px] md:border-2 border-[#000080] shadow-md" : ""
  }`;

  return (
    <button
      className={buttonClasses}
      style={{ backgroundColor: `${color}gold` }}
      onClick={onClick}
    >
      <img src={img} alt={alt} className="w-full h-full rounded-full" />
    </button>
  );
};

export default function ProductPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  const { i18n } = useTranslation();

  const queryParams = new URLSearchParams(routePath.search);
  let variantId = queryParams.get("variant");

  const { id } = useParams();
  const [stockCount, setStockCount] = useState(0);
  const [qty, setQty] = useState(1);
  const [isPair, setIsPair] = useState(false);
  const [size, setSize] = useState("0");
  // const [sizes, setSizes] = useState([]);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [mainImg, setMainImg] = useState(0);
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const [zoom, setZoom] = useState(1);
  const [isZoomed, setIsZoomed] = useState(false);
  const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });
  const [engravingTextMen, setEngravingTextMen] = useState(""); // State for engraving text
  const [engravingTextWomen, setEngravingTextWomen] = useState(""); // State for engraving text
  const [isAccordionOpen, setIsAccordionOpen] = useState(false); // State to track Accordion state
  const [selectedSizeMen, setSelectedSizeMen] = useState(0);
  const [selectedSizeWomen, setSelectedSizeWomen] = useState(0);

  const handleEngravingInput = (value, gender) => {
    const lettersAndSpaces = /^[A-Za-z ]*$/; // Regex to allow only letters and spaces
    if (lettersAndSpaces.test(value)) {
      if (gender === "female") {
        setEngravingTextWomen(value); // Update engraving text if input is valid
      } else {
        setEngravingTextMen(value); // Update engraving text if input is valid
      }
    }
  };

  const handleAccordionToggle = (isOpen) => {
    setIsAccordionOpen(isOpen); // Toggle Accordion state
    if (!isOpen) {
      setEngravingTextMen(""); // Clear engraving text when Accordion is closed
      setEngravingTextWomen(""); // Clear engraving text when Accordion is closed
    }
  };

  const [selectedColor, setSelectedColor] = useState("Yellow");

  // const [isOpen2, setIsOpen] = useState(false);
  const [isCustomized, setIsCustomized] = useState(false);
  const [numberOfCustomFields, setNumberOfCustomFields] = useState(0);

  const [customQuestions, setCustomQuestions] = useState([]);
  const [userResponses, setUserResponses] = useState([]);

  const [name1, setName1] = useState("");
  const [name2, setName2] = useState("");

  const handleColorChange = (name, sku, color) => {
    navigate(`/product/${name?.replaceAll(" ", "-")}/${sku}`);
    setSelectedColor(color);
  };

  const [pinCode, setPinCode] = useState("");
  const [isDeliveryAvailable, setIsDeliveryAvailable] = useState(null);

  const [isDragging, setIsDragging] = useState(false);

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    // dispatch(logout())
    setIsOpen(false);
  }

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  function closeModal() {
    setIsOpen(false);
  }

  const handleDragMove = (e) => {
    if (isDragging) {
      const image = e.target;
      const imageRect = image.getBoundingClientRect();
      const x = ((e.clientX - imageRect.left) / imageRect.width) * 100;
      const y = ((e.clientY - imageRect.top) / imageRect.height) * 100;
      setImagePosition({ x, y });
    }
  };

  const handleMouseOver = () => {
    setZoom(2.5);
    setIsZoomed(true);
  };

  const wishList = useSelector((state) => state.wishlist);
  const { wishlistItems, successRemove, successAdd } = wishList;

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const { success: successProductReview, error: errorProductReview } =
    productReviewCreate;

  const relatedProductList = useSelector((state) => state.relatedProductList);
  const {
    loading: loadingRelated,
    error: errorRelated,
    relatedProducts: productsRelated,
  } = relatedProductList;

  const ymalProductList = useSelector((state) => state.ymalProductList);
  const {
    loading: loadingYmal,
    error: errorYmal,
    products: productsYmal,
  } = ymalProductList;

  const sizeList = useSelector((state) => state.sizeList);
  const { loading: loadingSize, error: errorSize, sizes } = sizeList;

  const [scrolling, setScrolling] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (wishlistItems && wishlistItems?.length > 0) {
      if (wishlistItems?.some((item) => !item._id)) {
        dispatch(getWishlistItems());
      }
    }
  }, [wishlistItems]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (successProductReview) {
      alert("Review submitted");
      setRating(0);
      setComment("");
      dispatch({ type: PRODUCT_REVIEW_CREATE_RESET });
    }

    dispatch(listSizes());
    dispatch(relatedListProducts(id));
    dispatch(ymalListProducts(id));
    dispatch(listProductDetails(id));

    // let plate = id.split("-");
    // if (plate[1] === "Y") {
    //   setSelectedColor("Yellow");
    // } else if (plate[1] === "W") {
    //   setSelectedColor("White");
    // } else {
    //   setSelectedColor("Rose");
    // }
  }, [id, dispatch, successProductReview]);

  useEffect(() => {
    // Check if the product exists before tracking the event
    if (product && product._id) {
      // Fire the 'ViewContent' event when the product page is loaded
      ReactPixel.track("ViewContent", {
        content_ids: [product.sku], // Array of product IDs (required)
        content_type: "product", // Type of content being viewed (recommended)
      });
      console.log("ViewContent tracked for product:", product.id);
    }
  }, [product, id]); // Re-run if product data changes

  // useEffect(() => {
  //   document.addEventListener("mousemove", handleDragMove);
  //   document.addEventListener("mouseup", handleMouseUp);
  //   return () => {
  //     document.removeEventListener("mousemove", handleDragMove);
  //     document.removeEventListener("mouseup", handleMouseUp);
  //   };
  // }, [isDragging]);

  const handleAddToCart = (e) => {
    e.preventDefault();
    if (qty !== 0) {
      dispatch(
        addItemToCart(
          qty,
          size,
          id,
          "en",
          isCustomized,
          numberOfCustomFields,
          userResponses
        )
      );
      // onClose();
      notify("/cart", "Item Added To Cart", toast.success);
    } else {
      notify("#", "Please select size & quantity", toast.error);
    }
  };

  const handleAddToCart2 = (obj, isRing, toCart) => {
    console.log(isRing, obj.qty, obj.sizeMen, obj.sizeWomen);
    if (isRing) {
      if (obj.qty !== 0 && (obj.sizeMen !== 0 || obj.sizeWomen !== 0)) {
        dispatch(addItemToCart(obj));
        // onClose();
        if (toCart) {
          navigate("/cart");
        } else {
          notify("/cart", "Item Added To Cart", toast.success);
        }
      } else {
        notify("#", "Please Select Size & Quantity", toast.error);
      }
    } else {
      if (obj.qty !== 0) {
        dispatch(addItemToCart(obj));
        // onClose();
        if (toCart) {
          navigate("/cart");
        } else {
          notify("/cart", "Item Added To Cart", toast.success);
        }
      } else {
        notify("#", "Please Select Quantity", toast.error);
      }
    }
  };

  const addItemToWishlistHandler = (productId) => {
    dispatch(addItemToWishlist(productId));
    notify("/wishlist", "Item Added To Wishlist", toast.success);
  };

  const removeItemFromWishlistHandler = (id) => {
    dispatch(removeItemFromWishlist(id));
    notify("/wishlist", "Item Removed from Wishlist", toast.error);
  };

  const handleModalOpen = (
    // sizes,
    id,
    sent,
    customized,
    customFields,
    customQuestions
  ) => {
    // onOpen();
    setIsCustomized(customized);
    setNumberOfCustomFields(customFields);
    setCustomQuestions(customQuestions);
    const initialResponses = customQuestions?.map((question) => ({
      question,
      answer: "",
    }));
    setUserResponses(initialResponses);
    // setSizes(sizes);
  };

  const handleModalOpen2 = (id, customized, customFields, customQuestions) => {
    setIsOpen(true);
    setIsCustomized(customized);
    setNumberOfCustomFields(customFields);
    setCustomQuestions(customQuestions);
    const initialResponses = customQuestions?.map((question) => ({
      question,
      answer: "",
    }));
    setUserResponses(initialResponses);
  };

  const handleResponseChange = (index, event) => {
    const newResponses = [...userResponses];
    newResponses[index].answer = event.target.value;
    setUserResponses(newResponses);
  };

  const handleAddToCartCustom = (e) => {
    e.preventDefault();
    dispatch(
      addItemToCart(
        1,
        "1",
        id,
        "en",
        isCustomized,
        numberOfCustomFields,
        userResponses
      )
    );
    setIsOpen(false);
    notify("/cart", "Item Added To Cart", toast.success);
  };

  const handleImgChange = (img) => {
    setMainImg(img);
  };

  const handleSizeChange = (e) => {
    const selectedSize = e.target.value;
    setSize(selectedSize);

    const selectedSizeObj = product.sizes.find(
      (sizeObj) =>
        (i18n.language === "de" ? sizeObj.germanSize : sizeObj.indianSize) ===
        selectedSize
    );

    setStockCount(selectedSizeObj ? selectedSizeObj.countInStock : 0);
  };

  const handleQuantityChange = (value) => {
    if (value >= 1 && value <= 5) {
      setQty(value);
    }
  };

  const notify = (link, content, type) =>
    type(<RouterLink to={link}>{content}</RouterLink>);

  const imageStyle = {
    transform: `scale(${zoom})`,
    transition: isZoomed ? "transform 0.5s" : "none",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    transformOrigin: `${imagePosition.x}% ${imagePosition.y}%`,
  };

  const handleMouseOut = () => {
    setZoom(1);
    setIsZoomed(false);
    setImagePosition({ x: 0, y: 0 });
  };

  const handleMouseMove = (e) => {
    const image = e.target;
    const imageRect = image.getBoundingClientRect();
    const x = ((e.clientX - imageRect.left) / imageRect.width) * 100;
    const y = ((e.clientY - imageRect.top) / imageRect.height) * 100;
    setImagePosition({ x, y });
  };

  const handleTouchStart = () => {
    setZoom(2.5);
    setIsZoomed(true);
  };

  const handleTouchOut = (e) => {
    e.preventDefault();

    setZoom(1);
    setIsZoomed(false);
    setImagePosition({ x: 0, y: 0 });
  };

  const handleTouchMove = (e) => {
    e.stopPropagation();
    const image = e.target;
    const imageRect = image.getBoundingClientRect();
    const x = ((e.touches[0].clientX - imageRect.left) / imageRect.width) * 100;
    const y = ((e.touches[0].clientY - imageRect.top) / imageRect.height) * 100;
    setImagePosition({ x, y });
  };

  function isValidPinCode(str) {
    let regex = new RegExp(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/);
    if (str == null) {
      return "false";
    }
    if (regex.test(str) == true) {
      return true;
    } else {
      return false;
    }
  }

  const handlePinCodeChange = (e) => {
    setPinCode(e.target.value);
  };

  const handleCheckDelivery = async () => {
    try {
      let result = isValidPinCode(pinCode);
      if (result) {
        setIsDeliveryAvailable(result);
      } else {
        setIsDeliveryAvailable(false);
        throw new Error("Please check entered Pincode");
      }
    } catch (error) {
      console.error("Error checking delivery:", error);
      setIsDeliveryAvailable(false);
    }
  };

  return (
    <div className="">
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Size Chart(Indian Size)
                  </Dialog.Title>
                  <div className="mt-2 max-h-[60vh] overflow-y-auto">
                    <table className="w-full border-collapse border border-slate-500">
                      <tr className="text-center">
                        <th className="w-1/3 border border-slate-600 p-1">
                          Size
                        </th>
                        <th className="w-2/3 border border-slate-600 p-1">
                          Internal Diameter(mm.)
                        </th>
                      </tr>
                      {loadingSize ? (
                        <Loader />
                      ) : errorSize ? (
                        <Message type="error">{errorSize}</Message>
                      ) : (
                        sizes?.map((size) => (
                          <tr className="text-center">
                            <td className="border border-slate-700 p-1">
                              {size?.size}
                            </td>
                            <td className="border border-slate-700 p-1">
                              {size?.internalDiameterMM} mm.
                            </td>
                          </tr>
                        ))
                      )}
                    </table>
                  </div>

                  <div className="mt-4">
                    <button
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <section className="bg-white font-poppins">
        {loading ? (
          <div>Loading</div>
        ) : error ? (
          <div>Error</div>
        ) : (
          <div className="max-w-7xl px-4 py-4 mx-auto md:px-12">
            <Helmet>
              <title>{product.prodTitle || product.name}</title>
              <meta name="description" content={product.prodMetaDesc} />
              <meta
                property="og:image"
                content={
                  product.variants?.filter((obj) => obj._id === variantId)[0]
                    ?.images[0]
                }
              />
              <meta property="og:image:alt" content="Product Image" />
              <meta property="og:image:type" content="image/jpeg" />
              <meta property="og:image:width" content="1200" />
              <meta property="og:image:height" content="630" />
              {/* <script>
              fbq('track', 'ViewContent', {
              content_ids: ['123'], // 'REQUIRED': array of product IDs
              content_type: 'product', // RECOMMENDED: Either product or product_group based on the content_ids or contents being passed.
            })
            </script> */}
            </Helmet>
            <div className="grid grid-cols-1 md:grid-cols-2 -mx-4">
              {/* <div className="w-full">
                <div className="w-full border">
                  <Carousel
                    {...getConfigurableProps()}
                    selectedItem={mainImg}
                    onChange={handleImgChange}
                  >
                    {product.variants?.length > 0 &&
                      product.variants
                        ?.filter((variant) => variant._id === variantId)[0]
                        ?.images?.map((img, index) => (
                          <div key={index}>
                            <div
                              className="overflow-hidden hidden md:block"
                              onMouseDown={handleMouseDown}
                              onMouseOut={handleMouseOut}
                              onMouseMove={handleMouseMove}
                              onTouchStart={handleTouchStart}
                              onTouchMove={handleTouchMove}
                              onTouchCancel={handleTouchOut}
                              onMouseOver={handleMouseOver}
                            >
                              <img
                                src={img}
                                className="w-100 h-100 object-fit-contain mw-100"
                                style={imageStyle}
                                alt="image"
                              />
                            </div>
                            <div className="overflow-hidden block md:hidden">
                              <img
                                src={img}
                                className="w-100 h-100 object-fit-contain mw-100"
                                style={imageStyle}
                                alt="image"
                              />
                            </div>
                          </div>
                        ))}
                  </Carousel>
                </div>
                <div className="flex pt-4">
                  {product.variants?.length > 0 &&
                    product.variants
                      ?.filter((variant) => variant._id === variantId)[0]
                      ?.images?.map((img, idx) => (
                        <div className={`p-1 w-1/5`} key={img}>
                          <button
                            className={`block border border-[#000080] ${
                              mainImg === idx ? "border-2 border-[#000080]" : ""
                            }`}
                            onClick={() => handleImgChange(idx)}
                          >
                            <img
                              src={img}
                              alt=""
                              className="object-cover w-full cursor-pointer"
                            />
                          </button>
                        </div>
                      ))}
                </div>
              </div> */}
              <div className="w-full px-4 md:px-8 sticky top-0">
                {/* <div className="grid grid-cols-2 gap-2">
                  {product.variants?.length > 0 &&
                    product.variants
                      ?.filter((variant) => variant._id === variantId)[0]
                      ?.images?.map((img, index) => (
                        <div
                          className={`col-span-${
                            index === 0 ? "2" : "1"
                          } border`}
                        >
                          <img src={img} alt={`Product-image-${index + 1}`} />
                          <img
                            src={`https://www.facebook.com/tr?id=1327632928208462&ev=ViewContent&cd[content_name]=${product.name}&cd[content_category]=${product?.categories[0]?.name}&cd[content_type]=product&cd[content_ids]=${product.sku}&cd[value]=${product.price}&cd[currency]=INR`}
                            height="1"
                            width="1"
                            style={{ display: "none" }}
                          />
                        </div>
                      ))}
                </div> */}
                <ProductImages product={product} variantId={variantId} />
              </div>
              <div className="w-full px-4 md:px-8">
                <div className="sticky top-0">
                  <div className="flex flex-col gap-4 mb-3 md:mb-6">
                    <div className="flex gap-4 items-center">
                      <h2 className="text-lg font-bold md:text-lg text-justify">
                        {product.name}
                      </h2>
                      <button
                        className="flex rounded-3xl items-center justify-center p-2 text-[#000080] text-lg rounded-md"
                        onClick={() => {
                          wishlistItems?.some(
                            (item) => item._id === product._id
                          )
                            ? removeItemFromWishlistHandler(product._id)
                            : addItemToWishlistHandler(product._id);
                        }}
                      >
                        <Icon
                          as={
                            wishlistItems?.some(
                              (item) => item._id === product._id
                            )
                              ? AiFillHeart
                              : AiOutlineHeart
                          }
                          w="6"
                          h="6"
                          className={`${
                            wishlistItems?.some(
                              (item) => item._id === product._id
                            )
                              ? ""
                              : "animate-twice animate-jump animate-ease-in-out"
                          } ml-1 mb-1`}
                          // textColor="#000080"
                          cursor="pointer"
                        />
                      </button>
                      <ShareButton />
                    </div>
                    <h3>SKU - {product?.sku}</h3>
                    <p className="mt-2 text-[12px] text-gray-400 text-justify">
                      {product.metaDescription}
                    </p>
                    <div className="flex gap-2 items-end">
                      <p className="inline-block p-1 text-2xl font-semibold text-black">
                        <span>
                          ₹{" "}
                          {isPair
                            ? (product.price * 2)?.toLocaleString("en-IN")
                            : product.price?.toLocaleString("en-IN")}
                        </span>
                      </p>
                      <p
                        className={`inline-block p-1 text-lg line-through text-gray-400 ${
                          product.basePrice === 0 ? "hidden" : ""
                        }`}
                      >
                        <span>
                          ₹{" "}
                          {isPair
                            ? (product.basePrice * 2)?.toLocaleString("en-IN")
                            : product.basePrice?.toLocaleString("en-IN")}
                        </span>
                      </p>
                      <p
                        className={`${
                          product.discountedPercent === 0 ? "hidden" : ""
                        } inline-block p-1 text-lg text-[#000080]`}
                      >
                        -
                        {(
                          ((product.basePrice - product.price) /
                            product.basePrice) *
                          100
                        )?.toFixed(0)}
                        %
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-6 mb-6">
                    <div>
                      <label htmlFor="quantitySelect">Quantity:</label>
                      <div className="flex items-center">
                        <button
                          onClick={() =>
                            handleQuantityChange(qty > 1 ? qty - 1 : 1)
                          }
                          className="px-4 py-2 bg-[#F3F3F3] text-[#000080] font-bold text-xl focus:outline-none"
                        >
                          -
                        </button>
                        <input
                          type="text"
                          id="quantitySelect"
                          value={qty}
                          onChange={(e) =>
                            handleQuantityChange(Number(e.target.value))
                          }
                          className="px-4 py-2 w-[45px] text-center text-xl bg-[#F3F3F3] focus:outline-none border-none"
                          readOnly
                        />
                        <button
                          onClick={() =>
                            handleQuantityChange(qty < 5 ? qty + 1 : 5)
                          }
                          className="px-4 py-2 bg-[#F3F3F3] text-[#000080] font-bold text-xl focus:outline-none"
                        >
                          +
                        </button>
                      </div>
                    </div>
                    {product?.categories?.some(
                      (cat) => cat.name === "ANKLETS"
                    ) && (
                      <Box bg="#fff" py={4} borderRadius="md" width="300px">
                        <Text
                          fontSize="lg"
                          fontWeight="bold"
                          color="#000080"
                          mb={2}
                        >
                          Choose Anklet Type
                        </Text>
                        <RadioGroup
                          onChange={(value) => setIsPair(value === "pair")}
                          value={isPair ? "pair" : "single"}
                        >
                          <Stack direction="row" spacing={6}>
                            <Radio
                              value="single"
                              colorScheme="blue"
                              borderColor="#000080"
                              size="lg"
                              _checked={{
                                bg: "#000080",
                                color: "white",
                                borderColor: "#000080",
                              }}
                            >
                              <Text color="#000080" fontWeight="medium">
                                Single
                              </Text>
                            </Radio>
                            <Radio
                              value="pair"
                              colorScheme="blue"
                              borderColor="#000080"
                              size="lg"
                              _checked={{
                                bg: "#000080",
                                color: "white",
                                borderColor: "#000080",
                              }}
                            >
                              <Text color="#000080" fontWeight="medium">
                                Pair
                              </Text>
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      </Box>
                    )}
                    {product?.categories?.some((cat) => cat.name === "Rings") &&
                      (product?.subcategories?.some(
                        (cat) => cat.name === "Couple Rings"
                      ) ? (
                        <div className="flex flex-col gap-4">
                          <div className="flex gap-2 items-center">
                            <label htmlFor="sizeSelect">Size Men:</label>
                            <Select
                              id="sizeSelect"
                              border="1px solid"
                              _hover="none"
                              className="px-4 pt-0"
                              width="36"
                              borderColor="[#000080]"
                              value={selectedSizeMen}
                              onChange={(e) =>
                                setSelectedSizeMen(e.target.value)
                              }
                              required
                            >
                              <option value={0}>Select Size</option>
                              {loadingSize ? (
                                <Loader />
                              ) : errorSize ? (
                                <Message type="error">{errorSize}</Message>
                              ) : (
                                sizes.map((num) => (
                                  <option key={num?.size} value={num?.size}>
                                    {num?.size}
                                  </option>
                                ))
                              )}
                            </Select>
                            {/* <div
                              className="underline cursor-pointer"
                              onClick={() => setIsOpen(true)}
                            >
                              Size Chart
                            </div> */}
                          </div>
                          <div className="flex gap-2 items-center">
                            <label htmlFor="sizeSelect">Size Women:</label>
                            <Select
                              id="sizeSelect"
                              border="1px solid"
                              _hover="none"
                              className="px-4 pt-0"
                              width="36"
                              borderColor="[#000080]"
                              value={selectedSizeWomen}
                              onChange={(e) =>
                                setSelectedSizeWomen(e.target.value)
                              }
                              required
                            >
                              <option value={0}>Select Size</option>
                              {loadingSize ? (
                                <Loader />
                              ) : errorSize ? (
                                <Message type="error">{errorSize}</Message>
                              ) : (
                                sizes.map((num) => (
                                  <option key={num?.size} value={num?.size}>
                                    {num?.size}
                                  </option>
                                ))
                              )}
                            </Select>
                          </div>
                          <div
                            className="underline cursor-pointer"
                            onClick={() => setIsOpen(true)}
                          >
                            Size Chart
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-col gap-4">
                          <label htmlFor="sizeSelect">Size:</label>
                          <Select
                            id="sizeSelect"
                            border="1px solid"
                            _hover="none"
                            className="px-4 pt-0"
                            width="36"
                            borderColor="[#000080]"
                            value={
                              product?.gender === "female"
                                ? selectedSizeWomen
                                : selectedSizeMen
                            }
                            onChange={(e) => {
                              if (product?.gender === "female") {
                                setSelectedSizeWomen(e.target.value);
                              } else {
                                setSelectedSizeMen(e.target.value);
                              }
                            }}
                            required
                          >
                            <option value={0}>Select Size</option>
                            {loadingSize ? (
                              <Loader />
                            ) : errorSize ? (
                              <Message type="error">{errorSize}</Message>
                            ) : (
                              sizes.map((num) => (
                                <option key={num?.size} value={num?.size}>
                                  {num?.size}
                                </option>
                              ))
                            )}
                          </Select>
                          <div
                            className="underline cursor-pointer"
                            onClick={() => setIsOpen(true)}
                          >
                            Size Chart
                          </div>
                        </div>
                      ))}
                  </div>
                  {/* 
                  <div className="flex gap-2 items-center px-4 py-4 border mb-6">
                    <FaRegEdit className="w-5 h-5" />
                    <button
                      onClick={() => {
                        setEngraving(!engraving);
                      }}
                    >
                      Add Engraving
                    </button>
                  </div> */}
                  {product?.categories?.some((cat) => cat.name === "Rings") &&
                    (product?.subcategories?.some(
                      (cat) => cat.name === "Couple Rings"
                    ) ? (
                      <Accordion
                        allowMultiple
                        onChange={() => handleAccordionToggle(!isAccordionOpen)}
                        my="6"
                      >
                        <AccordionItem>
                          <h2>
                            <AccordionButton>
                              <FaRegEdit className="w-5 h-5" />
                              <Box as="span" flex="1" textAlign="left">
                                Add Engraving
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            <input
                              type="text"
                              maxLength="10" // Limits input to 10 characters
                              pattern="[A-Za-z]*" // Allows only letters and spaces
                              value={engravingTextMen} // Controlled input
                              className="border px-2 py-1 rounded mb-3"
                              placeholder="Engraving For Men"
                              onChange={(e) =>
                                handleEngravingInput(e.target.value, "male")
                              } // Handle engraving input
                            />
                            <p className="text-sm text-gray-500">
                              Max 10 characters, letters only.
                            </p>
                            <input
                              type="text"
                              maxLength="10" // Limits input to 10 characters
                              pattern="[A-Za-z]*" // Allows only letters and spaces
                              value={engravingTextWomen} // Controlled input
                              className="border px-2 py-1 rounded"
                              placeholder="Engraving for women"
                              onChange={(e) =>
                                handleEngravingInput(e.target.value, "female")
                              } // Handle engraving input
                            />
                            <p className="text-sm text-gray-500">
                              Max 10 characters, letters only.
                            </p>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    ) : (
                      <Accordion
                        allowMultiple
                        onChange={() => handleAccordionToggle(!isAccordionOpen)}
                        my="6"
                      >
                        <AccordionItem>
                          <h2>
                            <AccordionButton>
                              <FaRegEdit className="w-5 h-5" />
                              <Box as="span" flex="1" textAlign="left">
                                Add Engraving
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            <input
                              type="text"
                              maxLength="10" // Limits input to 10 characters
                              pattern="[A-Za-z]*" // Allows only letters and spaces
                              value={
                                product?.gender === "female"
                                  ? engravingTextWomen
                                  : engravingTextMen
                              } // Controlled input
                              className="border px-2 py-1 rounded"
                              placeholder="Engraving text"
                              onChange={(e) =>
                                handleEngravingInput(
                                  e.target.value,
                                  product.gender
                                )
                              } // Handle engraving input
                            />
                            <p className="text-sm text-gray-500">
                              Max 10 characters, letters only.
                            </p>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    ))}

                  <div className="flex gap-2 mb-6">
                    <div className="w-1/2 group">
                      <button
                        className="flex rounded-3xl items-center justify-center w-full p-2 text-lg border rounded-md enbaled:cursor-pointer disabled:cursor-not-allowed bg-[#000080] border-[#000080] text-gray-100"
                        onClick={() => {
                          if (userInfo && userInfo.firstName) {
                            handleAddToCart2(
                              {
                                name: product.name,
                                image:
                                  product.variants?.length > 0 &&
                                  product.variants?.filter(
                                    (variant) => variant._id === variantId
                                  )[0]?.images[0],
                                qty,
                                sizeMen: selectedSizeMen,
                                sizeWomen: selectedSizeWomen,
                                price: product.price,
                                product: product._id,
                                variant: variantId,
                                sku: product.sku,
                                giftWrap: false,
                                message: "",
                                engraveMen: engravingTextMen,
                                engraveWomen: engravingTextWomen,
                                isPair,
                              },
                              product.categories?.some(
                                (cat) => cat.name === "Rings"
                              ),
                              false
                            );
                          } else {
                            navigate("/signup");
                          }
                        }}
                      >
                        Add to Cart
                        <AiOutlineShoppingCart className="ml-1 mb-1 animate-twice animate-jump animate-ease-in-out" />
                      </button>
                    </div>
                    <div className="w-1/2">
                      {/* <button
                        className="flex rounded-3xl items-center justify-center w-full p-2 text-[#000080] text-lg border border-[#000080] rounded-md hover:bg-[#000080] hover:border-[#000080] hover:text-gray-100"
                        // onClick={addItemToWishlistHandler}
                      >
                        Add to wishlist
                        <AiOutlineHeart className="ml-1 mb-1 animate-infinite animate-jump animate-ease-in-out" />
                      </button> */}
                      <button
                        className="flex rounded-3xl items-center justify-center w-full p-2 text-[#000080] text-lg border border-[#000080] rounded-md hover:bg-[#000080] hover:border-[#000080] hover:text-gray-100"
                        onClick={() => {
                          if (userInfo && userInfo.firstName) {
                            handleAddToCart2(
                              {
                                name: product.name,
                                image:
                                  product.variants?.length > 0 &&
                                  product.variants?.filter(
                                    (variant) => variant._id === variantId
                                  )[0]?.images[0],
                                qty,
                                sizeMen: selectedSizeMen,
                                sizeWomen: selectedSizeWomen,
                                price: product.price,
                                product: product._id,
                                variant: variantId,
                                sku: product.sku,
                                giftWrap: false,
                                message: "",
                                engraveMen: engravingTextMen,
                                engraveWomen: engravingTextWomen,
                                isPair,
                              },
                              product.categories?.some(
                                (cat) => cat.name === "Rings"
                              ),
                              true
                            );
                          } else {
                            navigate("/signup");
                          }
                        }}
                      >
                        Buy Now
                      </button>
                    </div>
                  </div>

                  <div>
                    <h1 className="font-semibold">Select Plating Color</h1>
                    <div className="flex gap-4 p-4 justify-center items-center">
                      {product?.variants?.map((variant) => (
                        <div
                          className={`w-1/4 aspect-[1] border ${
                            variantId === variant._id
                              ? "border-[#000080] border-2"
                              : ""
                          }`}
                        >
                          <RouterLink
                            to={`/product/${product.name?.replaceAll(
                              " ",
                              "-"
                            )}/${product._id}?variant=${variant?._id}`}
                          >
                            <img
                              src={variant?.images[0]}
                              alt={variant?.color}
                            />
                          </RouterLink>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="grid gap-4 grid-cols-3 bg-[#F3F3F3] p-6 mb-6">
                    <div className="flex flex-col gap-2 items-center">
                      <img
                        src="https://d8v879q3glvao.cloudfront.net/Static_images/fet2.png"
                        alt="fet2"
                        className="w-[40px] h-[40px]"
                      />
                      <h3 className="text-center text-[12px] font-semibold">
                        Pure Sterling Silver Used
                      </h3>
                    </div>
                    <div className="flex flex-col gap-2 items-center">
                      <img
                        src="https://d8v879q3glvao.cloudfront.net/Static_images/BIS-Hallmark-Black.png"
                        alt="fet4"
                        className="w-[40px] h-[40px]"
                      />
                      <h3 className="text-center text-[12px] font-semibold">
                        BIS Hallmarked Jewellery
                      </h3>
                    </div>
                    <div className="flex flex-col gap-2 items-center">
                      <img
                        src="https://d8v879q3glvao.cloudfront.net/Static_images/fet1.png"
                        alt="fet1"
                        className="w-[40px] h-[40px]"
                      />
                      <h3 className="text-center text-[12px] font-semibold">
                        3 Months 18K Plating Warranty
                      </h3>
                    </div>
                  </div>

                  <Accordion allowMultiple>
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            Product Description
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4} className="whitespace-pre-line">
                        {product.description}
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            Free Delivery
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        Free Delivery All Over India
                      </AccordionPanel>
                    </AccordionItem>

                    {/* <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            Free Delivery
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        Free Delivery All Over India
                      </AccordionPanel>
                    </AccordionItem> */}
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      <div className="max-w-6xl px-0 md:px-4 py-4 mx-auto ">
        <PromoProductCard
          name={"GOES WELL WITH"}
          products={productsYmal}
          sts={productsYmal?.length}
          loading={loadingYmal}
          error={errorYmal}
        />
        <PromoProductCard
          name={"RELATED PRODUCTS"}
          products={productsRelated}
          sts={productsRelated?.length}
          loading={loadingRelated}
          error={errorRelated}
        />
      </div>
    </div>
  );
}
