import { useLocation } from "react-router-dom";
import { useEffect } from "react";

import RingsHero from "../components/RingsHero";
import CategoryFilter from "../components/CategoryFilter";
import MenuFilterComp from "../components/MenuFilterComp";

export default function Rings() {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    onTop();
  }, [routePath]);
  const queryParams = new URLSearchParams(routePath.search);
  let categoriesQuery = queryParams.get("categories") || null;
  let subCategoriesQuery = queryParams.get("subcategories") || null;
  let collectionsQuery = queryParams.get("collections") || null;
  let isLatestArrivalQuery = queryParams.get("isLatestArrival") || null;
  let genderQuery = queryParams.get("gender") || null;
  let stylesQuery = queryParams.get("styles") || null;

  return (
    <div>
      <MenuFilterComp
        categoriesQuery={categoriesQuery}
        collectionsQuery={collectionsQuery}
        subCategoriesQuery={subCategoriesQuery}
        isLatestArrivalQuery={isLatestArrivalQuery}
        genderQuery={genderQuery}
        stylesQuery={stylesQuery}
      />

      <CategoryFilter
        name="Collections"
        categoriesQuery={categoriesQuery}
        collectionsQuery={collectionsQuery}
        subCategoriesQuery={subCategoriesQuery}
        isLatestArrivalQuery={isLatestArrivalQuery}
        genderQuery={genderQuery}
        stylesQuery={stylesQuery}
      />
    </div>
  );
}
