import {
  CATEGORY_CREATE_REQUEST,
  CATEGORY_CREATE_SUCCESS,
  CATEGORY_CREATE_FAIL,
  CATEGORY_CREATE_RESET,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_DETAILS_REQUEST,
  CATEGORY_DETAILS_SUCCESS,
  CATEGORY_DETAILS_FAIL,
  CATEGORY_UPDATE_REQUEST,
  CATEGORY_UPDATE_SUCCESS,
  CATEGORY_UPDATE_FAIL,
  CATEGORY_UPDATE_RESET,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_DELETE_FAIL,
  CAT_SUBCATEGORY_LIST_REQUEST,
  CAT_SUBCATEGORY_LIST_SUCCESS,
  CAT_SUBCATEGORY_LIST_FAIL,
  CAT_SUBCATEGORY_LIST_RESET,
} from "../constants/categoryConstants";

const initialState = {
  categories: [],
  subcategories: [],
};

export const categoryCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case CATEGORY_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
        success: true,
      };
    case CATEGORY_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case CATEGORY_CREATE_RESET:
      return {
        ...state,
        categories: [],
        loading: false,
        error: null,
        success: false,
      };
    default:
      return state;
  }
};

export const categoryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case CATEGORY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const catSubcategoryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case CAT_SUBCATEGORY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CAT_SUBCATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case CAT_SUBCATEGORY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CAT_SUBCATEGORY_LIST_RESET:
      return { ...state, error: null, subcategories: [] };
    default:
      return state;
  }
};

export const categoryDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CATEGORY_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case CATEGORY_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const categoryUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CATEGORY_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
        success: true,
      };
    case CATEGORY_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CATEGORY_UPDATE_RESET:
      return {
        ...state,
        categories: [],
        loading: false,
        error: null,
        success: false,
      };
    default:
      return state;
  }
};

export const categoryDeleteReducer = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case CATEGORY_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case CATEGORY_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    default:
      return state;
  }
};
