// src/components/Cart.js
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  getCartItems,
  addItemToCart,
  updateCartItem,
  removeItemFromCart,
  clearCart,
} from "../actions/cartActions";
import { FaRegEdit } from "react-icons/fa";
import {
  AccordionIcon,
  AccordionPanel,
  Box,
  Accordion,
  AccordionButton,
  AccordionItem,
} from "@chakra-ui/react";
import { CiSquareRemove } from "react-icons/ci";
import EngravingInput from "../components/EngravingInput";
import GiftInput from "../components/GiftInput";
import { Dialog, Transition } from "@headlessui/react";
import { BiMessageSquareEdit } from "react-icons/bi";
import { IoMdCloseCircle } from "react-icons/io";
import { scrollToTop } from "../components/ScrollToTop";
import { checkCoupon } from "../actions/couponActions";
import Loader from "../components/Loader";
import Message from "../components/Message";

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [code, setCode] = useState("");
  const [engravingText, setEngravingText] = useState(""); // State for engraving text
  const [openAccordions, setOpenAccordions] = useState({});
  const [isAccordionOpen, setIsAccordionOpen] = useState(false); // State to track Accordion state
  const [selectedSize, setSelectedSize] = useState(0);

  const cart = useSelector((state) => state.cart);
  const { cartItems, successAdd, successUpdate, successRemove } = cart;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const couponValidation = useSelector((state) => state.couponValidation);
  const { loading, error: errorCoupon, isValid, discount } = couponValidation;

  useEffect(() => {
    scrollToTop();
    if (!userInfo) {
      navigate("/signup");
    } else {
      dispatch(getCartItems());
    }
  }, [dispatch, successAdd, successUpdate, successRemove]);

  useEffect(() => {
    if (cartItems && cartItems?.length > 0) {
      if (cartItems?.some((item) => !item.product?._id)) {
        dispatch(getCartItems());
      }
    }
  }, [cartItems]);

  cart.itemsPrice = cart.cartItems?.reduce(
    (acc, item) => (acc + item.isPair ? item.price * 2 : item.price * item.qty),
    0
  );
  cart.giftWrapTotalCost = cart.cartItems?.reduce(
    (acc, currVal) => acc + (currVal.giftWrap ? currVal.qty * 50 : 0),
    0
  );
  cart.shippingPrice = 0; //cart.itemsPrice < 1000 ? 1000 : 0;
  cart.taxPrice = 0; //(18 * cart.itemsPrice) / 100;
  cart.discount = cart.itemsPrice * (discount / 100);
  cart.totalPrice =
    cartItems?.reduce(
      (acc, item) =>
        acc + item.isPair ? item.price * 2 : item.price * item.qty,
      0
    ) +
    (cartItems?.reduce(
      (acc, item) => acc + 10 * item?.engraveMen.length * item.qty,
      0
    ) +
      cartItems?.reduce(
        (acc, item) => acc + 10 * item?.engraveWomen.length * item.qty,
        0
      )) +
    cartItems
      ?.filter((item) => item.giftWrap)
      ?.reduce((acc, item) => acc + 50 * item.qty, 0) -
    cart.discount;

  const handleGiftWrapChange = (
    productId,
    variantId,
    qty,
    sizeMen,
    sizeWomen,
    giftWrap,
    message,
    engraveMen,
    engraveWomen
  ) => {
    dispatch(
      updateCartItem(
        productId,
        variantId,
        qty,
        sizeMen,
        sizeWomen,
        !giftWrap,
        message,
        engraveMen,
        engraveWomen
      )
    );
  };

  const handleMessageChange = (
    productId,
    variantId,
    qty,
    sizeMen,
    sizeWomen,
    giftWrap,
    value,
    engraveMen,
    engraveWomen
  ) => {
    console.log(value);
    dispatch(
      updateCartItem(
        productId,
        variantId,
        qty,
        sizeMen,
        sizeWomen,
        giftWrap,
        value,
        engraveMen,
        engraveWomen
      )
    );
  };

  const handleAccordionToggle = (isOpen) => {
    setIsAccordionOpen(isOpen); // Toggle Accordion state
    if (!isOpen) {
      setEngravingText(""); // Clear engraving text when Accordion is closed
    }
  };

  const toggleAccordion = (itemId) => {
    setOpenAccordions((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId], // Toggle the state for the specific item
    }));
  };

  const increaseQtyHandler = (
    productId,
    variantId,
    qty,
    sizeMen,
    sizeWomen,
    giftWrap,
    message,
    engraveMen,
    engraveWomen
  ) => {
    dispatch(
      updateCartItem(
        productId,
        variantId,
        qty + 1,
        sizeMen,
        sizeWomen,
        giftWrap,
        message,
        engraveMen,
        engraveWomen
      )
    );
  };

  const decreaseQtyHandler = (
    productId,
    variantId,
    qty,
    sizeMen,
    sizeWomen,
    giftWrap,
    message,
    engraveMen,
    engraveWomen
  ) => {
    if (qty > 1) {
      dispatch(
        updateCartItem(
          productId,
          variantId,
          qty - 1,
          sizeMen,
          sizeWomen,
          giftWrap,
          message,
          engraveMen,
          engraveWomen
        )
      );
    } else {
      dispatch(removeItemFromCart(productId, variantId))
    }
  };

  const removeFromCartHandler = (productId, variantId) => {
    dispatch(removeItemFromCart(productId, variantId));
  };

  const clearCartHandler = () => {
    dispatch(clearCart());
  };

  const handlePlaceOrder = () => {
    navigate("/shipping");
  };

  const handleCouponApply = () => {
    cart.couponCode = code;
    dispatch(checkCoupon(code));
  };

  return (
    <div className="max-w-6xl mx-auto p-6">
      <h2 className="text-2xl mb-4 text-center font-semibold">Cart Items</h2>
      {cartItems?.length === 0 ? (
        <div className="flex flex-col gap-3 w-full items-center justify-center col-span-5">
          <h3 className="text-[#000080] text-base font-semibold">
            Your cart is empty.
          </h3>
          <img
            src="../../empty-cart.png"
            className="w-1/2 md:w-1/4"
            alt="Your cart is empty. "
          />
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          <div>
            {cartItems?.map((item) => (
              <div
                key={`${item.product?._id}-${item.variant}`}
                className="flex flex-col"
              >
                <div className="flex relative items-center gap-6 mt-4 py-4 border-t-2 border-gray-200">
                  <Link
                    to={`/product/${item.product.name?.replaceAll(" ", "-")}/${
                      item.product._id
                    }?variant=${
                      item.product?.variants?.length > 0 &&
                      item.product?.variants?.filter(
                        (variant) => variant._id === item.variant
                      )[0]?._id
                    }`}
                    className="flex w-1/3 items-center border aspect-1 rounded-lg"
                  >
                    <img
                      src={item.image}
                      alt={item.name}
                      className="object-center object-cover rounded-lg"
                    />
                  </Link>

                  <div className="flex w-2/3 flex-col text-[12px] font-semibold gap-2">
                    <Link
                      to={`/product/${item.product.name?.replaceAll(
                        " ",
                        "-"
                      )}/${item.product._id}?variant=${
                        item.product?.variants?.length > 0 &&
                        item.product?.variants?.filter(
                          (variant) => variant._id === item.variant
                        )[0]?._id
                      }`}
                    >
                      {item.name}
                    </Link>
                    <div
                      className={`${
                        item?.product?.categories?.some(
                          (cat) => cat.name === "Rings"
                        )
                          ? "block"
                          : "hidden"
                      }`}
                    >
                      {item?.product?.subcategories?.some(
                        (subcat) => subcat?.name === "Couple Rings"
                      ) ? (
                        <div className="flex flex-col">
                          <div>Size Men - {item.sizeMen}</div>
                          <div>Size Women - {item.sizeWomen}</div>
                        </div>
                      ) : (
                        <div>
                          Size
                          {item?.product?.gender === "female"
                            ? ` - ${item.sizeWomen}`
                            : ` - ${item.sizeMen}`}
                        </div>
                      )}
                    </div>

                    <div
                      className={`${
                        item?.product?.categories?.some(
                          (cat) => cat.name === "ANKLETS"
                        )
                          ? "block"
                          : "hidden"
                      }`}
                    >
                      <div>Style- {item.isPair ? "Pair" : "Single"}</div>
                    </div>

                    <div className="flex items-center">
                      <button
                        onClick={() =>
                          decreaseQtyHandler(
                            item.product?._id,
                            item.variant,
                            item.qty
                          )
                        }
                        className="px-2 py-1 border"
                      >
                        -
                      </button>
                      <span className="px-2">{item.qty}</span>
                      <button
                        onClick={() =>
                          increaseQtyHandler(
                            item.product?._id,
                            item.variant,
                            item.qty
                          )
                        }
                        className="px-2 py-1 border"
                      >
                        +
                      </button>
                    </div>

                    <div className="flex gap-3 items-end">
                      <h3 className="font-bold text-[16px]">
                        ₹{" "}
                        {item.isPair
                          ? (item.price * 2 * item.qty)?.toLocaleString("en-IN")
                          : (item.price * item.qty)?.toLocaleString("en-IN")}
                      </h3>
                      <h3 className="text-[12px] font-[500] text-gray-400 line-through">
                        ₹{" "}
                        {item.isPair
                          ? (
                              item.product?.basePrice *
                              2 *
                              item.qty
                            )?.toLocaleString("en-IN")
                          : (
                              item?.product?.basePrice * item.qty
                            )?.toLocaleString("en-IN")}
                      </h3>
                      <h3
                        className={`${
                          item?.product?.discountedPercent === 0 ? "hidden" : ""
                        } inline-block text-[12px] text-[#000080]`}
                      >
                        -
                        {(
                          ((item?.product?.basePrice - item?.product?.price) /
                            item?.product?.basePrice) *
                          100
                        )?.toFixed(0)}
                        %
                      </h3>
                    </div>
                    <button
                      onClick={() => toggleAccordion(item._id)}
                      className="flex truncate gap-2 px-2 py-1 items-center rounded-md border border-gray-300 uppercase"
                    >
                      <BiMessageSquareEdit className="w-5 h-5" />
                      <p
                        className={`${
                          item?.product?.categories?.some(
                            (cat) => cat.name === "Rings"
                          )
                            ? "block"
                            : "hidden"
                        }`}
                      >
                        Change Size,{" "}
                      </p>
                      <p>Add Engraving and Gift Wrap</p>
                    </button>

                    {openAccordions[item._id] && (
                      // <EngravingInput
                      //   key={item._id}
                      //   item={item}
                      //   setIsAccordionOpen={() => toggleAccordion(item._id)}
                      // />
                      <Transition appear show={true} as={Fragment}>
                        <Dialog
                          as="div"
                          className="relative z-20"
                          onClose={() => toggleAccordion(item._id)}
                        >
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <div className="fixed inset-0 bg-black/25" />
                          </Transition.Child>

                          <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center">
                              <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                              >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                  <Dialog.Title
                                    as="h3"
                                    className="flex justify-between items-center text-lg font-medium leading-6 text-gray-900"
                                  >
                                    <h3>Manage Details</h3>
                                    <button
                                      onClick={() => toggleAccordion(item._id)}
                                    >
                                      <IoMdCloseCircle className="w-5 h-5 text-red-600" />
                                    </button>
                                  </Dialog.Title>
                                  <div className="mt-2">
                                    <EngravingInput
                                      key={item._id}
                                      item={item}
                                      setIsAccordionOpen={() =>
                                        toggleAccordion(item._id)
                                      }
                                    />
                                  </div>

                                  {/* <div className="mt-4">
                                        <button
                                          type="button"
                                          className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                          onClick={closeModal}
                                        >
                                          Got it, thanks!
                                        </button>
                                      </div> */}
                                </Dialog.Panel>
                              </Transition.Child>
                            </div>
                          </div>
                        </Dialog>
                      </Transition>
                    )}
                  </div>
                  <button
                    onClick={() =>
                      removeFromCartHandler(item.product?._id, item.variant)
                    }
                    className="absolute top-4 right-4 px-2 py-1"
                  >
                    <CiSquareRemove className="w-6 h-6" />
                  </button>
                </div>
                {/* <GiftInput key={item._id} item={item} /> */}
              </div>
            ))}
          </div>
          <div className="flex w-full flex-col gap-6">
            <div className="flex bg-gray-100 p-4 items-center gap-4 justify-center mb-4">
              <img src="https://d8v879q3glvao.cloudfront.net/products/1730092469534.png" />
              <p>
                Yay! <strong>No Delivery Charge</strong> on this order.
              </p>
            </div>

            <div className="flex flex-col gap-4 relative mb-4">
              <div className="font-bold">Have a Coupon?</div>
              <input
                className="p-2 bg-gray-100 border-1 border-gray-300 rounded"
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder="Enter Coupon Code"
              />
              <button
                onClick={() => handleCouponApply()}
                className="absolute right-4 top-[50px] text-sm text-[#000080] font-bold"
              >
                APPLY
              </button>
              {loading ? (
                <Loader />
              ) : errorCoupon ? (
                <Message type="error">{errorCoupon}</Message>
              ) : (
                <div>Thank You For Applying Coupon</div>
              )}
            </div>

            <div className="flex flex-col gap-6">
              <h2 className="font-bold">{`PRICE DETAILS (${
                cartItems?.length + 1
              } ITEMS)`}</h2>
              <div className="flex justify-between text-sm text-gray-500">
                <div>Total MRP</div>
                <div>
                  ₹{" "}
                  {cartItems
                    ?.reduce(
                      (acc, item) =>
                        acc + item.isPair
                          ? item.product?.basePrice * 2
                          : item.product?.basePrice * item.qty,
                      0
                    )
                    ?.toLocaleString("en-IN")}
                </div>
              </div>
              <div className="flex justify-between text-sm text-gray-500">
                <div>Discount on MRP</div>
                <div className="text-green-500">
                  -₹{" "}
                  {(
                    cartItems?.reduce(
                      (acc, item) =>
                        acc + item.isPair
                          ? item.product?.basePrice * 2
                          : item.product?.basePrice * item.qty,
                      0
                    ) -
                    cartItems?.reduce(
                      (acc, item) =>
                        acc + item.isPair
                          ? item.product?.price * 2
                          : item.product?.price * item.qty,
                      0
                    )
                  )?.toLocaleString("en-IN")}
                </div>
              </div>
              {isValid && (
                <div className="flex justify-between text-sm text-gray-500">
                  <p className="">Coupon Discount({discount}%)</p>
                  <p className="text-green-500">
                    -₹ {cart.discount?.toFixed(0)}
                  </p>
                </div>
              )}

              {isValid && (
                <div className="flex justify-between text-sm text-gray-500">
                  <p className="">Coupon Code</p>
                  <p className="">{cart.couponCode}</p>
                </div>
              )}
              {cartItems?.some(
                (item) => item?.engraveMen !== "" || item.engraveWomen !== ""
              ) && (
                <div className="flex justify-between text-sm text-gray-500">
                  <div>Engraving Charge (₹ 10 Per Character)</div>
                  <div>
                    {(
                      cartItems?.reduce(
                        (acc, item) =>
                          acc + 10 * item?.engraveMen.length * item.qty,
                        0
                      ) +
                      cartItems?.reduce(
                        (acc, item) =>
                          acc + 10 * item?.engraveWomen.length * item.qty,
                        0
                      )
                    )?.toLocaleString("en-IN")}
                  </div>
                </div>
              )}
              {cartItems?.some((item) => item?.giftWrap) && (
                <div className="flex justify-between text-sm text-gray-500">
                  <div>Gift Charge</div>
                  <div>
                    ₹{" "}
                    {cartItems
                      ?.filter((item) => item.giftWrap)
                      ?.reduce((acc, item) => acc + 50 * item.qty, 0)
                      ?.toLocaleString("en-IN")}
                  </div>
                </div>
              )}

              <div className="flex justify-between text-sm text-gray-500">
                <div>Delivery Charge</div>
                <div className="text-green-500">Free</div>
              </div>

              <div className="flex justify-between border-t-2 border-gray-200 font-bold pt-4">
                <div>Total Amount</div>
                <div>
                  ₹{" "}
                  {(
                    cartItems?.reduce(
                      (acc, item) =>
                        acc + item.isPair
                          ? item.price * 2
                          : item.price * item.qty,
                      0
                    ) +
                    (cartItems?.reduce(
                      (acc, item) =>
                        acc + 10 * item?.engraveMen.length * item.qty,
                      0
                    ) +
                      cartItems?.reduce(
                        (acc, item) =>
                          acc + 10 * item?.engraveWomen.length * item.qty,
                        0
                      )) +
                    cartItems
                      ?.filter((item) => item.giftWrap)
                      ?.reduce((acc, item) => acc + 50 * item.qty, 0) -
                    cart.discount
                  )?.toLocaleString("en-IN")}
                </div>
              </div>

              <div className="flex w-full">
                <button
                  onClick={() => handlePlaceOrder()}
                  className="w-full bg-[#000080] flex text-white p-4 font-semibold gap-4 justify-center items-center"
                >
                  Place Order
                  <img
                    src="https://d8v879q3glvao.cloudfront.net/products/1730092371657.png"
                    alt="arr"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-center w-full px-4 my-10 lg:mb-0">
        <Link
          to={`/`}
          className="flex items-center justify-center w-full md:w-1/5 p-2 md:p-3 text-[#000080] border border-[#000080] rounded-md hover:bg-[#000080] hover:border-[#000080] hover:text-gray-100"
        >
          Continue Shopping
        </Link>
      </div>
    </div>
  );
};

export default Cart;
