// import axios from "axios";
// import {
//   CART_ADD_ITEM,
//   CART_REMOVE_ITEM,
//   CART_SAVE_PAYMENT_METHOD,
//   CART_SAVE_SHIPPING_ADDRESS,
// } from "../constants/cartConstants";
// import { serverIp } from "../config/conf";

// export const addToCart = (obj) => async (dispatch, getState) => {
//   // const { data } = await axios.get(`${serverIp}/api/products/${id}`);

//   // {
//   //   name: product.name,
//   //   image:
//   //     product.variants?.length > 0 &&
//   //     product.variants?.filter(
//   //       (variant) => variant._id === variantId
//   //     )[0]?.images[0],
//   //   qty,
//   //   sizeMen: "0",
//   //   sizeWomen: `${selectedSize}`,
//   //   price: product.price,
//   //   product: product._id,
//   //   variant: variantId,
//   //   sku: product.sku,
//   //   giftWrap: false,
//   //   message: "",
//   //   engraveMen: "",
//   //   engraveWomen: engravingText,
//   // }

//   const cartItem = {
//     productId: obj.product,
//     variantId: obj.variant,
//     qty: obj.qty,
//     sizeMen: obj.sizeMen,
//     sizeWomen: obj.sizeWomen,
//     giftWrap: obj.giftWrap,
//     message: obj.message,
//     engraveMen: obj.engraveMen,
//     engraveWomen: obj.engraveWomen,
//   };

//   const {
//     userLogin: { userInfo },
//   } = getState();

//   userInfo &&
//     (await axios.post(`${serverIp}/api/cart`, cartItem, {
//       headers: {
//         Authorization: `Bearer ${userInfo.token}`,
//       },
//     }));

//   dispatch({
//     type: CART_ADD_ITEM,
//     payload: cartItem,
//   });

//   localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
// };

// export const removeFromCart =
//   (id, size, userResponses) => async (dispatch, getState) => {
//     const {
//       userLogin: { userInfo },
//     } = getState();

//     userInfo &&
//       (await axios.post(
//         `${serverIp}/api/users/removeFromCart/${id}?size=${size}`,
//         { userResponses },
//         {
//           headers: {
//             Authorization: `Bearer ${userInfo.token}`,
//           },
//         }
//       ));

//     dispatch({ type: CART_REMOVE_ITEM, payload: { id, size, userResponses } });

//     localStorage.setItem(
//       "cartItems",
//       JSON.stringify(getState().cart.cartItems)
//     );
//   };

// export const saveShippingAddress = (data) => (dispatch) => {
//   dispatch({ type: CART_SAVE_SHIPPING_ADDRESS, payload: data });

//   localStorage.setItem("shippingAddress", JSON.stringify(data));
// };

// export const savePaymentMethod = (data) => (dispatch) => {
//   dispatch({ type: CART_SAVE_PAYMENT_METHOD, payload: data });

//   localStorage.setItem("paymentMethod", JSON.stringify(data));
// };

// src/actions/cartActions.js
import axios from "axios";
import {
  CART_GET_ITEM,
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_UPDATE_ITEM,
  CART_CLEAR_ITEMS,
  CART_SET_SHIPPING_ADDRESS,
} from "../constants/cartConstants";
import { serverIp } from "../config/conf";

export const getCartItems = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${serverIp}/api/cart`, config);
    dispatch({
      type: CART_GET_ITEM,
      payload: data.cart.items,
    });
    localStorage.setItem(
      "cartItems",
      JSON.stringify(getState().cart.cartItems)
    );
  } catch (error) {
    console.error("Error fetching cart items:", error);
  }
};

export const addItemToCart = (obj) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${serverIp}/api/cart`,
      {
        name: obj.name,
        image: obj.image,
        qty: obj.qty,
        sizeMen: obj.sizeMen,
        sizeWomen: obj.sizeWomen,
        price: obj.price,
        productId: obj.product,
        variantId: obj.variant,
        sku: obj.sku,
        giftWrap: obj.giftWrap,
        message: obj.message,
        engraveMen: obj.engraveMen,
        engraveWomen: obj.engraveWomen,
        isPair: obj.isPair,
      },
      config
    );

    dispatch({
      type: CART_ADD_ITEM,
      payload: data.cart.items,
    });

    localStorage.setItem(
      "cartItems",
      JSON.stringify(getState().cart.cartItems)
    );
  } catch (error) {
    console.error("Error adding item to cart:", error);
  }
};

export const updateCartItem =
  (
    productId,
    variantId,
    qty,
    sizeMen,
    sizeWomen,
    giftWrap,
    message,
    engraveMen,
    engraveWomen
  ) =>
  async (dispatch, getState) => {
    console.log(qty);
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${serverIp}/api/cart/${productId}/${variantId}`,
        {
          qty,
          sizeMen,
          sizeWomen,
          giftWrap,
          message,
          engraveMen,
          engraveWomen,
        },
        config
      );

      dispatch({
        type: CART_UPDATE_ITEM,
        payload: data.cart.items,
      });

      localStorage.setItem(
        "cartItems",
        JSON.stringify(getState().cart.cartItems)
      );
    } catch (error) {
      console.error("Error updating cart item:", error);
    }
  };

export const removeItemFromCart =
  (productId, variantId) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.delete(
        `${serverIp}/api/cart/${productId}/${variantId}`,
        config
      );

      dispatch({
        type: CART_REMOVE_ITEM,
        payload: data.cart.items,
      });

      localStorage.setItem(
        "cartItems",
        JSON.stringify(getState().cart.cartItems)
      );
    } catch (error) {
      console.error("Error removing item from cart:", error);
    }
  };

export const clearCart = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`${serverIp}/api/cart`, config);

    dispatch({
      type: CART_CLEAR_ITEMS,
    });

    localStorage.removeItem("cartItems");
  } catch (error) {
    console.error("Error clearing the cart:", error);
  }
};

export const setShippingAddress = (addressId) => (dispatch, getState) => {
  dispatch({
    type: CART_SET_SHIPPING_ADDRESS,
    payload: addressId,
  });

  // Optionally, save it to localStorage if you want to persist it
  localStorage.setItem(
    "shippingAddress",
    JSON.stringify(getState().cart.shippingAddress)
  );
};
