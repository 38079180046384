import {
	ORDER_CREATE_FAIL,
	ORDER_CREATE_REQUEST,
	ORDER_CREATE_RESET,
	ORDER_CREATE_SUCCESS,
	ORDER_DELIVER_FAIL,
	ORDER_DELIVER_REQUEST,
	ORDER_DELIVER_RESET,
	ORDER_DELIVER_SUCCESS,
	ORDER_DETAILS_FAIL,
	ORDER_DETAILS_REQUEST,
	ORDER_DETAILS_RESET,
	ORDER_DETAILS_SUCCESS,
	ORDER_LIST_FAIL,
	ORDER_LIST_REQUEST,
	ORDER_LIST_SUCCESS,
	ORDER_MY_LIST_FAIL,
	ORDER_MY_LIST_REQUEST,
	ORDER_MY_LIST_RESET,
	ORDER_MY_LIST_SUCCESS,
	ORDER_PAY_FAIL,
	ORDER_PAY_REQUEST,
	ORDER_PAY_RESET,
	ORDER_PAY_SUCCESS,
	ORDER_STATS_FAIL,
	ORDER_STATS_REQUEST,
	ORDER_STATS_SUCCESS,
	ORDER_STATUS_FAIL,
	ORDER_STATUS_REQUEST,
	ORDER_STATUS_RESET,
	ORDER_STATUS_SUCCESS,
	CLAIM_WARRANTY_FAILURE,
	CLAIM_WARRANTY_REQUEST,
	CLAIM_WARRANTY_SUCCESS,
	CLAIM_WARRANTY_RESET,
	USER_WARRANTY_CLAIMS_REQUEST,
	USER_WARRANTY_CLAIMS_SUCCESS,
	USER_WARRANTY_CLAIMS_FAILURE
} from '../constants/orderConstants';

export const orderCreateReducer = (state = {}, action) => {
	switch (action.type) {
		case ORDER_CREATE_REQUEST:
			return { loading: true };
		case ORDER_CREATE_SUCCESS:
			return { loading: false, success: true, ...action.payload };
		case ORDER_CREATE_FAIL:
			return { loading: false, error: action.payload };
			case ORDER_CREATE_RESET:
			return {};
		default:
			return state;
	}
};

export const orderDetailsReducer = (
	state = { orderItems: [], shippingAddress: {}, loading: true },
	action
) => {
	switch (action.type) {
		case ORDER_DETAILS_REQUEST:
			return { ...state, loading: true };
		case ORDER_DETAILS_SUCCESS:
			return { loading: false, ...action.payload };
		case ORDER_DETAILS_FAIL:
			return { loading: false, error: action.payload };
		case ORDER_DETAILS_RESET:
			return { orderItems: [], shippingAddress: {}, loading: true };
		default:
			return state;
	}
};

export const orderPayReducer = (state = {}, action) => {
	switch (action.type) {
		case ORDER_PAY_REQUEST:
			return { loading: true };
		case ORDER_PAY_SUCCESS:
			return { loading: false, success: true };
		case ORDER_PAY_FAIL:
			return { loading: false, error: action.payload };
		case ORDER_PAY_RESET:
			return {};
		default:
			return state;
	}
};

export const orderMyListReducer = (state = { data: [] }, action) => {
	switch (action.type) {
		case ORDER_MY_LIST_REQUEST:
			return { loading: true };
		case ORDER_MY_LIST_SUCCESS:
			return { loading: false, ...action.payload };
		case ORDER_MY_LIST_FAIL:
			return { loading: false, error: action.payload };
		case ORDER_MY_LIST_RESET:
			return { data: [] };
		default:
			return state;
	}
};

export const orderListReducer = (state = { data: [] }, action) => {
	switch (action.type) {
		case ORDER_LIST_REQUEST:
			return { loading: true };
		case ORDER_LIST_SUCCESS:
			return { loading: false, ...action.payload };
		case ORDER_LIST_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const orderDeliverReducer = (state = {}, action) => {
	switch (action.type) {
		case ORDER_DELIVER_REQUEST:
			return { loading: true };
		case ORDER_DELIVER_SUCCESS:
			return { loading: false, success: true };
		case ORDER_DELIVER_FAIL:
			return { loading: false, error: action.payload };
		case ORDER_DELIVER_RESET:
			return {};
		default:
			return state;
	}
};

export const orderStatusReducer = (state = {}, action) => {
	switch (action.type) {
		case ORDER_STATUS_REQUEST:
			return { loading: true };
		case ORDER_STATUS_SUCCESS:
			return { loading: false, success: true };
		case ORDER_STATUS_FAIL:
			return { loading: false, error: action.payload };
		case ORDER_STATUS_RESET:
			return {};
		default:
			return state;
	}
};

const initialState = {
	loading: false,
	count: [],
	totalSales: 0,
	error: null,
};

export const orderStatsReducer = (state = initialState, action) => {
	switch (action.type) {
		case ORDER_STATS_REQUEST:
			return { ...state, loading: true };
		case ORDER_STATS_SUCCESS:
			return {
				...state,
				loading: false,
				count: action.payload.count,
				totalSales: action.payload.totalSales,
				error: null
			};
		case ORDER_STATS_FAIL:
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
	}
};

const initialState2 = {
    claimingWarranty: false,
    claimWarrantySuccess: null,
    claimWarrantyError: null,
};

export const warrantyReducer = (state = initialState2, action) => {
    switch (action.type) {
        case CLAIM_WARRANTY_REQUEST:
            return {
                ...state,
                claimingWarranty: true,
                claimWarrantySuccess: null,
                claimWarrantyError: null,
            };
        case CLAIM_WARRANTY_SUCCESS:
            return {
                ...state,
                claimingWarranty: false,
                claimWarrantySuccess: action.payload.success,
                claimWarrantyError: null,
            };
        case CLAIM_WARRANTY_FAILURE:
            return {
                ...state,
                claimingWarranty: false,
                claimWarrantySuccess: null,
                claimWarrantyError: action.payload.error,
            };
		case CLAIM_WARRANTY_RESET:
			return initialState2
        default:
            return state;
    }
};

export const WarrantyClaimsListReducer = (state = { claims: [] }, action) => {
	switch (action.type) {
		case USER_WARRANTY_CLAIMS_REQUEST:
			return { loading: true };
		case USER_WARRANTY_CLAIMS_SUCCESS:
			return { loading: false, claims: action.payload };
		case USER_WARRANTY_CLAIMS_FAILURE:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};