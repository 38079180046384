import {
  Flex,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { ImCheckboxChecked } from "react-icons/im";
import { CiDeliveryTruck } from "react-icons/ci";
import { BsPaypal } from "react-icons/bs";
import {
  claimWarranty,
  listMyOrders,
  getUserWarrantyClaims,
} from "../actions/orderActions";
import { ORDER_DETAILS_RESET } from "../constants/orderConstants";
import { scrollToTop } from "../components/ScrollToTop";
import { MdOutlinePayment, MdOutlinePending } from "react-icons/md";
import { FcCancel, FcProcess } from "react-icons/fc";
import { AiOutlineFileDone } from "react-icons/ai";
import ClaimForm from "../components/ClaimForm";
import Sidebar from "../components/Sidebar";

export default function ClaimsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const WarrantyClaimsList = useSelector((state) => state.WarrantyClaimsList);
  const { loading, error, claims } = WarrantyClaimsList;

  useEffect(() => {
    scrollToTop();
    if (!userInfo) {
      navigate(`/signup`);
    } else {
      dispatch(getUserWarrantyClaims());
    }
  }, [dispatch]);

  return (
    <div className="flex flex-col gap-4 md:gap-8 p-6 bg-white">
      <div className="flex mx-auto">
        <p className="text-lg md:text-2xl font-bold underline underline-offset-8">
          My Claims
        </p>
      </div>

      {loading ? (
        <div>Loading</div>
      ) : error ? (
        <div>Error</div>
      ) : (
        <div className="flex flex-col">
          {claims.map((claim, index) => (
            <div
              key={index}
              className="flex flex-col w-full md:w-4/5 self-center border border-gray-300 rounded-md mb-4 md:mb-8"
            >
              <div className="flex flex-wrap md:flex-nowrap justify-between p-2 md:p-4 items-center border-b border-gray-200">
                <div className="flex flex-wrap md:flex-nowrap gap-4 md:gap-8">
                  <div>
                    <p className="font-semibold">Order Id</p>
                    <p className="text-sm text-gray-400">{claim.order?._id}</p>
                  </div>
                  <div>
                    <p className="font-semibold">Order Number</p>
                    <p className="text-sm text-gray-400">{claim.order?.order_id}</p>
                  </div>
                  <div>
                    <p className="font-semibold">Date Placed</p>
                    <p className="text-sm text-gray-400">
                      {claim.claimDate.substring(0, 10)}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col p-2 md:p-4 border-b border-gray-200">
                <p className="font-semibold">Product Name</p>
                <p className="text-sm text-gray-400">{claim.product?.name}</p>
              </div>

              <div className="flex flex-col p-2 md:p-4 border-b border-gray-200">
                <p className="font-semibold">Claim Message</p>
                <p className="text-sm text-gray-400">{claim.claimMessage}</p>
              </div>

              <div className="flex p-4 md:p-8 justify-between gap-8 border-b border-gray-200">
                {claim.claimPhotos?.map((img, idx) => (
                  <div className="w-1/5" key={idx}>
                    <img className="rounded-md" src={img} alt={img} />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
