import axios from "axios";

import {
  CLAIM_WARRANTY_FAILURE,
  CLAIM_WARRANTY_REQUEST,
  CLAIM_WARRANTY_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_DELIVER_FAIL,
  ORDER_DELIVER_REQUEST,
  ORDER_DELIVER_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_MY_LIST_FAIL,
  ORDER_MY_LIST_REQUEST,
  ORDER_MY_LIST_SUCCESS,
  ORDER_PAY_FAIL,
  ORDER_PAY_REQUEST,
  ORDER_PAY_SUCCESS,
  ORDER_STATS_FAIL,
  ORDER_STATS_REQUEST,
  ORDER_STATS_SUCCESS,
  ORDER_STATUS_FAIL,
  ORDER_STATUS_REQUEST,
  ORDER_STATUS_SUCCESS,
  USER_WARRANTY_CLAIMS_FAILURE,
  USER_WARRANTY_CLAIMS_REQUEST,
  USER_WARRANTY_CLAIMS_SUCCESS,
} from "../constants/orderConstants";
import { serverIp } from "../config/conf";

export const createOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(`${serverIp}/api/orders`, order, config);

    dispatch({ type: ORDER_CREATE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const getOrderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${serverIp}/api/orders/${id}`, config);

    dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const payOrder =
  (orderId, paymentResult) => async (dispatch, getState) => {
    try {
      dispatch({ type: ORDER_PAY_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.put(
        `${serverIp}/api/orders/${orderId}/pay`,
        paymentResult,
        config
      );

      dispatch({ type: ORDER_PAY_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: ORDER_PAY_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

export const listMyOrders = (state) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_MY_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${serverIp}/api/orders/myorders?status=${state}`,
      config
    );

    dispatch({ type: ORDER_MY_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: ORDER_MY_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const listOrders = (sortFor, sortTo) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${serverIp}/api/orders?sortFor=${sortFor}&sortTo=${sortTo}`,
      config
    );

    dispatch({ type: ORDER_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: ORDER_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const deliverOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_DELIVER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.put(
      `${serverIp}/api/orders/${order._id}/deliver`,
      order,
      config
    );

    dispatch({ type: ORDER_DELIVER_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: ORDER_DELIVER_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const updateOrderStatus =
  (id, status, payment) => async (dispatch, getState) => {
    try {
      dispatch({ type: ORDER_STATUS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.put(
        `${serverIp}/api/orders/${id}/status`,
        { status, payment },
        config
      );

      dispatch({ type: ORDER_STATUS_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: ORDER_STATUS_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

export const getOrderStats =
  (startDate, endDate, state, sortFor, sortTo) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ORDER_STATS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${serverIp}/api/orders/stats?startDate=${startDate}&endDate=${endDate}&status=${state}&sortFor=${sortFor}&sortTo=${sortTo}`,
        config
      );

      dispatch({
        type: ORDER_STATS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ORDER_STATS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const claimWarranty =
  (orderId, productId, variantId, claimMessage, claimPhotos) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: CLAIM_WARRANTY_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };

      // Make the API request to claim warranty
      const response = await axios.post(
        `${serverIp}/api/claims/${orderId}/claim-warranty`,
        {
          productId,
          variantId,
          claimMessage,
          claimPhotos,
        },
        config
      );

      dispatch({
        type: CLAIM_WARRANTY_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: CLAIM_WARRANTY_FAILURE,
        payload: error.response ? error.response.data : "Network error",
      });
    }
  };

export const getUserWarrantyClaims = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_WARRANTY_CLAIMS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    // Make the API request to claim warranty
    const response = await axios.get(
      `${serverIp}/api/claims/warranty-claims`,
      config
    );

    dispatch({
      type: USER_WARRANTY_CLAIMS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: USER_WARRANTY_CLAIMS_FAILURE,
      payload: error.response ? error.response.data : "Network error",
    });
  }
};
