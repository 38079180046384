import { useEffect, useState } from "react";
import { listProductSets } from "../actions/productSetAction";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Slider from "react-slick"; // Import the slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md"; // Icons for next/prev arrows
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "./Loader";
import Message from "./Message";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import {
  addItemToWishlist,
  getWishlistItems,
  removeItemFromWishlist,
} from "../actions/wishListActions";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { addItemToCart } from "../actions/cartActions";
import { FaRegEdit } from "react-icons/fa";
import { listSizes } from "../actions/sizeActions";

const getConfigurableProps = () => ({
  showArrows: true,
  showStatus: false,
  // showIndicators: true,
  infiniteLoop: true,
  showThumbs: false,
  useKeyboardArrows: true,
  autoPlay: true,
  stopOnHover: true,
  swipeable: true,
  dynamicHeight: true,
  emulateTouch: true,
  autoFocus: false,
  thumbWidth: 100,
  selectedItem: 0,
  interval: 2000,
  transitionTime: 800,
  swipeScrollTolerance: 5,
});

export default function Collections() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const notify = (link, content, type) =>
    type(<RouterLink to={link}>{content}</RouterLink>);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productSetList = useSelector((state) => state.productSetList);
  const { loading, error, productSets } = productSetList;

  const wishList = useSelector((state) => state.wishlist);
  const { wishlistItems } = wishList;

  const sizeList = useSelector((state) => state.sizeList);
  const { loading: loadingSize, error: errorSize, sizes } = sizeList;

  const [qty, setQty] = useState(1);
  const [sentToCart, setSentToCart] = useState(false);
  const [engravingTextMen, setEngravingTextMen] = useState(""); // State for engraving text
  const [engravingTextWomen, setEngravingTextWomen] = useState("");
  const [selectedPlating, setSelectedPlating] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedSizeMen, setSelectedSizeMen] = useState(0);
  const [selectedSizeWomen, setSelectedSizeWomen] = useState(0);
  const [isCustomized, setIsCustomized] = useState(false);
  const [customQuestions, setCustomQuestions] = useState([]);
  const [userResponses, setUserResponses] = useState([]);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  useEffect(() => {
    dispatch(listProductSets());
    dispatch(getWishlistItems());
    dispatch(listSizes());
  }, [dispatch]);

  const settings1 = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 9000,
    pauseOnHover: true,
    // prevArrow: <SamplePrevArrow />,
    // nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings2 = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    // prevArrow: <SamplePrevArrow />,
    // nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleAddToCart = (e, obj) => {
    e.preventDefault();
    if (qty !== 0) {
      dispatch(addItemToCart(obj));
      onClose();
      if (sentToCart) {
        navigate("/cart");
      } else {
        notify("/cart", "Item Added To Cart", toast.success);
      }
    } else {
      notify("#", "Please select size & quantity", toast.error);
    }
  };

  const handleResponseChange = (index, event) => {
    const newResponses = [...userResponses];
    newResponses[index].answer = event.target.value;
    setUserResponses(newResponses);
  };

  const handleQuantityChange = (value) => {
    if (value >= 1 && value <= 5) {
      setQty(value);
    }
  };

  const handleEngravingInput = (gender, value) => {
    const lettersAndSpaces = /^[A-Za-z ]*$/; // Regex to allow only letters and spaces
    if (lettersAndSpaces.test(value)) {
      if (gender === "female") {
        setEngravingTextWomen(value); // Update engraving text if input is valid
      } else {
        setEngravingTextMen(value); // Update engraving text if input is valid
      }
    }
  };

  const handleAccordionToggle = (isOpen) => {
    setIsAccordionOpen(isOpen); // Toggle Accordion state
    if (!isOpen) {
      setEngravingTextMen(""); // Clear engraving text when Accordion is closed
      setEngravingTextWomen(""); // Clear engraving text when Accordion is closed
    }
  };

  const removeItemFromWishlistHandler = (id) => {
    dispatch(removeItemFromWishlist(id));
    notify("/wishlist", "Item Removed from Wishlist", toast.error);
  };

  const addItemToWishlistHandler = (productId) => {
    dispatch(addItemToWishlist(productId));
    notify("/wishlist", "Item Added To Wishlist", toast.success);
  };

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-12">
      <p className="text-center text-xl font-semibold py-6">
        MASTERPIECE BY SAIRAH
      </p>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message type="error">{error}</Message>
      ) : (
        <div className="w-full min-h-[20vh] md:min-h-[70vh] bg-white">
          <Slider {...settings1}>
            {productSets.map((productSet, index) => (
              <div>
                <div
                  key={index}
                  className="flex flex-col md:flex-row w-full items-center justify-center py-12 gap-10"
                >
                  <div className="flex items-center justify-center w-full md:w-1/2">
                    <div className="w-full md:w-1/2">
                      {/* <Carousel {...getConfigurableProps()}> */}
                      <Slider {...settings2}>
                        {productSet?.products?.map((product, index) => (
                          <div
                            key={index}
                            className="flex h-full flex-col justify-between relative p-2 group/prod"
                          >
                            <RouterLink
                              className="w-1/2"
                              to={`/product/${product.name?.replaceAll(
                                " ",
                                "-"
                              )}/${product._id}?variant=${
                                product?.variants?.length > 0 &&
                                product?.variants?.filter(
                                  (variant) => variant.isPrimary
                                )[0]?._id
                              }`}
                            >
                              <div className="aspect-h-1 aspect-w-1 overflow-hidden bg-white">
                                {product?.variants?.length > 0 ? (
                                  <img
                                    src={
                                      product?.variants?.length > 0 &&
                                      product?.variants?.filter(
                                        (variant) => variant.isPrimary
                                      )[0]?.images[0]
                                    }
                                    alt={product?.name}
                                    loading="lazy"
                                    className={
                                      "self-center hover:scale-125 transition-transform ease-in object-cover object-center m-auto bg-[#EBEAE5] text-center"
                                    }
                                  />
                                ) : (
                                  <div className="flex h-full w-full bg-gray-100 items-center justify-center">
                                    <span>Image</span>
                                  </div>
                                )}
                              </div>
                              <h3 className="py-1 mt-3 text-center px-2 text-base font-semibold text-gray-800 h-6 overflow-clip truncate">
                                {product.name}
                              </h3>
                              <h3 className="py-1 mt-3 text-center px-2 text-[14px] text-gray-500 h-6 overflow-clip truncate">
                                {product.description}
                              </h3>
                              <div className="flex flex-col mt-3 px-2 gap-2 md:gap-4 justify-between items-center">
                                <div className="flex w-full items-center justify-center gap-1 md:gap-3 ">
                                  {/* hidden group-hover/prod: */}
                                  <p className="text-[14px] text-center text-gray-400 line-through">
                                    ₹{" "}
                                    {product.basePrice?.toLocaleString("en-IN")}
                                  </p>
                                  <p className="text-[14px] text-center md:text-base font-semibold text-[#000080]">
                                    {(
                                      ((product.basePrice - product.price) /
                                        product.basePrice) *
                                      100
                                    ).toFixed(0)}
                                    %
                                  </p>
                                </div>
                                <div className="flex w-full items-center justify-center gap-1 md:gap-3">
                                  <p className="text-sm text-center md:text-base font-bold text-gray-900">
                                    ₹ {product.price?.toLocaleString("en-IN")}
                                  </p>
                                </div>
                              </div>
                            </RouterLink>
                            <div className="w-full pt-2 bg-white block">
                              {/* hidden group-hover/prod: */}
                              <div className="flex justify-between items-center gap-1 h-[40px]">
                                {product?.categories?.some(
                                  (cat) => cat.name === "Rings"
                                ) ? (
                                  <button
                                    className="w-3/4 h-full bg-[#000080] rounded text-center self-center text-white text-sm font-medium"
                                    onClick={() => {
                                      if (userInfo && userInfo.firstName) {
                                        onOpen();
                                        setSelectedProduct(product);
                                      } else {
                                        navigate(
                                          `/signup?redirect=/product/${product.name?.replaceAll(
                                            " ",
                                            "-"
                                          )}/${product._id}?variant=${
                                            product?.variants?.length > 0 &&
                                            product?.variants?.filter(
                                              (variant) => variant.isPrimary
                                            )[0]?._id
                                          }`
                                        );
                                      }
                                    }}
                                  >
                                    Add to Cart
                                  </button>
                                ) : (
                                  <button
                                    className="w-3/4 h-full bg-[#000080] rounded text-center self-center text-white text-sm font-medium"
                                    onClick={(e) => {
                                      if (userInfo && userInfo.firstName) {
                                        handleAddToCart(e, {
                                          name: product?.name,
                                          image:
                                            product?.variants?.length > 0 &&
                                            product?.variants?.filter(
                                              (variant) =>
                                                selectedPlating.length > 0
                                                  ? selectedPlating.includes(
                                                      variant.color
                                                    )
                                                  : variant.isPrimary
                                            )[0]?.images[0],
                                          qty,
                                          sizeMen: "1",
                                          sizeWomen: "1",
                                          price: product?.price,
                                          product: product?._id,
                                          variant:
                                            product?.variants?.length > 0 &&
                                            product?.variants?.filter(
                                              (variant) => variant?.isPrimary
                                            )[0]?._id,
                                          sku: product?.sku,
                                          giftWrap: false,
                                          message: "",
                                          engraveMen: engravingTextMen,
                                          engraveWomen: engravingTextWomen,
                                          isPair: false,
                                        });
                                      } else {
                                        navigate(
                                          `/signup?redirect=/product/${product.name?.replaceAll(
                                            " ",
                                            "-"
                                          )}/${product._id}?variant=${
                                            product?.variants?.length > 0 &&
                                            product?.variants?.filter(
                                              (variant) => variant.isPrimary
                                            )[0]?._id
                                          }`
                                        );
                                      }
                                    }}
                                  >
                                    Add to Cart
                                  </button>
                                )}

                                <button
                                  className="flex items-center justify-center w-1/4 h-full border border-[#000080] rounded"
                                  onClick={() => {
                                    wishlistItems?.some(
                                      (item) => item._id === product._id
                                    )
                                      ? removeItemFromWishlistHandler(
                                          product._id
                                        )
                                      : addItemToWishlistHandler(product._id);
                                  }}
                                >
                                  <Icon
                                    as={
                                      wishlistItems?.some(
                                        (item) => item._id === product._id
                                      )
                                        ? AiFillHeart
                                        : AiOutlineHeart
                                    }
                                    w="6"
                                    h="6"
                                    textColor="#000080"
                                    cursor="pointer"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>

                  {/* Main Product Set Image Section */}
                  <div className="flex flex-col gap-4 items-center justify-center w-full md:w-1/2">
                    <img
                      src={productSet.image} // Replace with the main product set image
                      alt="Main Product Set"
                      className="max-w-full"
                    />
                    <h2 className="text-[18px] font-[700]">
                      {productSet.name}
                    </h2>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      )}
      <Drawer onClose={onClose} isOpen={isOpen} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{`Select size and quantity`}</DrawerHeader>
          <DrawerBody>
            <form
              onSubmit={(e) =>
                handleAddToCart(e, {
                  name: selectedProduct?.name,
                  image:
                    selectedProduct?.variants?.length > 0 &&
                    selectedProduct?.variants?.filter((variant) =>
                      selectedPlating.length > 0
                        ? selectedPlating.includes(variant.color)
                        : variant.isPrimary
                    )[0]?.images[0],
                  qty,
                  sizeMen: selectedSizeMen,
                  sizeWomen: selectedSizeWomen,
                  price: selectedProduct?.price,
                  product: selectedProduct?._id,
                  variant:
                    selectedProduct?.variants?.length > 0 &&
                    selectedProduct?.variants?.filter(
                      (variant) => variant?.isPrimary
                    )[0]?._id,
                  sku: selectedProduct?.sku,
                  giftWrap: false,
                  message: "",
                  engraveMen: engravingTextMen,
                  engraveWomen: engravingTextWomen,
                  isPair: false,
                })
              }
            >
              <Tabs isManual variant="enclosed">
                <TabList>
                  <Tab>Select Size/Add Engraving</Tab>
                  <Tab>Size Chart(Indian Size)</Tab>
                  <Tab>How to measure</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Accordion
                      allowMultiple
                      onChange={() => handleAccordionToggle(!isAccordionOpen)}
                      my="6"
                    >
                      <AccordionItem>
                        <h2>
                          <AccordionButton>
                            <FaRegEdit className="w-5 h-5" />
                            <Box as="span" flex="1" textAlign="left">
                              Add Engraving
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        {selectedProduct?.subcategories?.some(
                          (subcat) => subcat.name === "Couple Rings"
                        ) ? (
                          <>
                            <AccordionPanel pb={4}>
                              <input
                                type="text"
                                maxLength="10" // Limits input to 10 characters
                                pattern="[A-Za-z]*" // Allows only letters and spaces
                                value={engravingTextMen} // Controlled input
                                className="border px-2 py-1 rounded"
                                placeholder="Engraving for men"
                                onChange={(e) =>
                                  handleEngravingInput("male", e.target.value)
                                } // Handle engraving input
                              />
                              <p className="text-sm text-gray-500">
                                Max 10 characters, letters only.
                              </p>
                            </AccordionPanel>
                            <AccordionPanel pb={4}>
                              <input
                                type="text"
                                maxLength="10" // Limits input to 10 characters
                                pattern="[A-Za-z]*" // Allows only letters and spaces
                                value={engravingTextWomen} // Controlled input
                                className="border px-2 py-1 rounded"
                                placeholder="Engraving for women"
                                onChange={(e) =>
                                  handleEngravingInput("female", e.target.value)
                                } // Handle engraving input
                              />
                              <p className="text-sm text-gray-500">
                                Max 10 characters, letters only.
                              </p>
                            </AccordionPanel>
                          </>
                        ) : (
                          <AccordionPanel pb={4}>
                            <input
                              type="text"
                              maxLength="10" // Limits input to 10 characters
                              pattern="[A-Za-z]*" // Allows only letters and spaces
                              value={
                                selectedProduct?.gender === "female"
                                  ? engravingTextWomen
                                  : engravingTextMen
                              } // Controlled input
                              className="border px-2 py-1 rounded"
                              placeholder={`Engraving for ${
                                selectedProduct?.gender === "female"
                                  ? "women"
                                  : "men"
                              }`}
                              onChange={(e) => {
                                if (selectedProduct?.gender === "female") {
                                  handleEngravingInput(
                                    "female",
                                    e.target.value
                                  );
                                } else {
                                  handleEngravingInput("male", e.target.value);
                                }
                              }} // Handle engraving input
                            />
                            <p className="text-sm text-gray-500">
                              Max 10 characters, letters only.
                            </p>
                          </AccordionPanel>
                        )}
                      </AccordionItem>
                    </Accordion>

                    <div className="flex flex-col gap-6 my-6">
                      <div>
                        <label htmlFor="quantitySelect">Quantity:</label>
                        <div className="flex items-center">
                          <button
                            type="button"
                            onClick={() =>
                              handleQuantityChange(qty > 1 ? qty - 1 : 1)
                            }
                            className="px-4 py-2 bg-[#F3F3F3] text-[#000080] font-bold text-xl focus:outline-none"
                          >
                            -
                          </button>
                          <input
                            type="text"
                            id="quantitySelect"
                            value={qty}
                            onChange={(e) =>
                              handleQuantityChange(Number(e.target.value))
                            }
                            className="px-4 py-2 w-[45px] text-center text-xl bg-[#F3F3F3] focus:outline-none border-none"
                            readOnly
                          />
                          <button
                            type="button"
                            onClick={() =>
                              handleQuantityChange(qty < 5 ? qty + 1 : 5)
                            }
                            className="px-4 py-2 bg-[#F3F3F3] text-[#000080] font-bold text-xl focus:outline-none"
                          >
                            +
                          </button>
                        </div>
                      </div>
                      {selectedProduct?.categories?.find(
                        (cat) => cat.name === "Rings"
                      ) && (
                        <div>
                          <label htmlFor="sizeSelect">Size:</label>
                          {selectedProduct?.subcategories?.some(
                            (subcat) => subcat.name === "Couple Rings"
                          ) ? (
                            <div className="flex gap-6">
                              <Select
                                id="sizeSelect"
                                border="1px solid"
                                _hover="none"
                                className="pt-0"
                                pt="0px"
                                borderColor="[#000080]"
                                value={selectedSizeMen}
                                onChange={(e) =>
                                  setSelectedSizeMen(e.target.value)
                                }
                                required
                              >
                                <option value={0}>Select Size Men</option>
                                {loadingSize ? (
                                  <Loader />
                                ) : errorSize ? (
                                  <Message type="error">{errorSize}</Message>
                                ) : (
                                  sizes.map((num) => (
                                    <option key={num?.size} value={num?.size}>
                                      {num?.size}
                                    </option>
                                  ))
                                )}
                              </Select>
                              <Select
                                id="sizeSelect"
                                border="1px solid"
                                _hover="none"
                                className="pt-0"
                                pt="0px"
                                borderColor="[#000080]"
                                value={selectedSizeWomen}
                                onChange={(e) =>
                                  setSelectedSizeWomen(e.target.value)
                                }
                                required
                              >
                                <option value={0}>Select Size Women</option>
                                {loadingSize ? (
                                  <Loader />
                                ) : errorSize ? (
                                  <Message type="error">{errorSize}</Message>
                                ) : (
                                  sizes.map((num) => (
                                    <option key={num?.size} value={num?.size}>
                                      {num?.size}
                                    </option>
                                  ))
                                )}
                              </Select>
                            </div>
                          ) : (
                            <Select
                              id="sizeSelect"
                              border="1px solid"
                              _hover="none"
                              className="pt-0"
                              pt="0px"
                              borderColor="[#000080]"
                              value={
                                selectedProduct?.gender === "female"
                                  ? selectedSizeWomen
                                  : selectedSizeMen
                              }
                              onChange={(e) => {
                                if (selectedProduct?.gender === "female") {
                                  setSelectedSizeWomen(e.target.value);
                                } else {
                                  setSelectedSizeMen(e.target.value);
                                }
                              }}
                              required
                            >
                              <option value={0}>Select Size</option>
                              {loadingSize ? (
                                <Loader />
                              ) : errorSize ? (
                                <Message type="error">{errorSize}</Message>
                              ) : (
                                sizes.map((num) => (
                                  <option key={num?.size} value={num?.size}>
                                    {num?.size}
                                  </option>
                                ))
                              )}
                            </Select>
                          )}
                        </div>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="mt-2 max-h-[60vh] overflow-y-auto">
                      <table className="w-full border-collapse border border-slate-500">
                        <tr className="text-center">
                          <th className="w-1/3 border border-slate-300 p-1">
                            Size
                          </th>
                          <th className="w-2/3 border border-slate-300 p-1">
                            Internal Diameter(mm.)
                          </th>
                        </tr>
                        {loadingSize ? (
                          <Loader />
                        ) : errorSize ? (
                          <Message type="error">{errorSize}</Message>
                        ) : (
                          sizes?.map((size) => (
                            <tr className="text-center">
                              <td className="border border-slate-300 p-1">
                                {size?.size}
                              </td>
                              <td className="border border-slate-300 p-1">
                                {size?.internalDiameterMM} mm.
                              </td>
                            </tr>
                          ))
                        )}
                      </table>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <p className="flex w-full justify-center items-center text-lg font-bold py-8">
                      If you dont have ring
                    </p>
                    <div className="grid grid-cols-2 gap-8">
                      <div className="flex justify-center items-center">
                        <img
                          src="https://d8v879q3glvao.cloudfront.net/Static_images/determine-ring-size-using-paper-2.png"
                          alt="instructions"
                          className="h-[120px]"
                        />
                      </div>
                      <div>
                        Take a small piece of string or thread or ribbon. Wrap
                        it around your finger.
                      </div>

                      <div className="flex justify-center items-center">
                        <img
                          src="https://d8v879q3glvao.cloudfront.net/Static_images/determine-ring-size-using-paper-3.webp"
                          alt="instructions"
                          className="h-[120px]"
                        />
                      </div>
                      <div>Mark the spot where the thread meets.</div>

                      <div className="flex justify-center items-center">
                        <img
                          src="https://d8v879q3glvao.cloudfront.net/Static_images/determine-ring-size-using-paper-4.webp"
                          alt="instructions"
                          className="h-[120px]"
                        />
                      </div>
                      <div>
                        Measure the length of the string with your ruler.
                      </div>
                    </div>

                    <p className="flex w-full justify-center items-center pt-8">
                      --------------------------------------------------------------------------------------------
                    </p>

                    <p className="flex w-full justify-center items-center text-lg font-bold pb-8">
                      If you have a ring
                    </p>
                    <div className="grid grid-cols-2 gap-8">
                      <div className="flex justify-center items-center">
                        <img
                          src="https://d8v879q3glvao.cloudfront.net/Static_images/Ring-size-guide-ruler.jpg"
                          alt="instructions"
                          className="h-[120px]"
                        />
                      </div>
                      <div>
                        1 : Place the ring
                        <br />2 : Measure the inner diameter within inner edges
                        of your ring with a ruler.
                      </div>
                    </div>
                  </TabPanel>
                </TabPanels>
              </Tabs>
              <Button
                type="submit"
                isDisabled={
                  selectedProduct?.gender === "female"
                    ? selectedSizeWomen === 0
                    : selectedSizeMen === 0
                }
                bgColor="#000080"
                textColor="white"
                _hover={{ bgColor: "#000090" }}
              >
                Next
              </Button>
            </form>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <MdNavigateNext
      className={className}
      style={{
        color: "gray",
        fontSize: "45px",
        height: "55px",
        width: "55px",
        paddingLeft: "20px",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <MdNavigateBefore
      className={className}
      style={{
        color: "gray",
        fontSize: "45px",
        height: "55px",
        width: "55px",
        paddingRight: "20px",
      }}
      onClick={onClick}
    />
  );
}
