// menuLinkConstants.js
export const MENU_LINK_CREATE_REQUEST = 'MENU_LINK_CREATE_REQUEST';
export const MENU_LINK_CREATE_SUCCESS = 'MENU_LINK_CREATE_SUCCESS';
export const MENU_LINK_CREATE_FAIL = 'MENU_LINK_CREATE_FAIL';
export const MENU_LINK_CREATE_RESET = 'MENU_LINK_CREATE_RESET';

export const MENU_LINK_LIST_REQUEST = 'MENU_LINK_LIST_REQUEST';
export const MENU_LINK_LIST_SUCCESS = 'MENU_LINK_LIST_SUCCESS';
export const MENU_LINK_LIST_FAIL = 'MENU_LINK_LIST_FAIL';

export const MENU_LINK_DETAILS_REQUEST = 'MENU_LINK_DETAILS_REQUEST';
export const MENU_LINK_DETAILS_SUCCESS = 'MENU_LINK_DETAILS_SUCCESS';
export const MENU_LINK_DETAILS_FAIL = 'MENU_LINK_DETAILS_FAIL';

export const MENU_LINK_UPDATE_REQUEST = 'MENU_LINK_UPDATE_REQUEST';
export const MENU_LINK_UPDATE_SUCCESS = 'MENU_LINK_UPDATE_SUCCESS';
export const MENU_LINK_UPDATE_FAIL = 'MENU_LINK_UPDATE_FAIL';
export const MENU_LINK_UPDATE_RESET = 'MENU_LINK_UPDATE_RESET';

export const MENU_LINK_DELETE_REQUEST = 'MENU_LINK_DELETE_REQUEST';
export const MENU_LINK_DELETE_SUCCESS = 'MENU_LINK_DELETE_SUCCESS';
export const MENU_LINK_DELETE_FAIL = 'MENU_LINK_DELETE_FAIL';
