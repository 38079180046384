export const STYLE_CREATE_REQUEST = 'STYLE_CREATE_REQUEST';
export const STYLE_CREATE_SUCCESS = 'STYLE_CREATE_SUCCESS';
export const STYLE_CREATE_FAIL = 'STYLE_CREATE_FAIL';
export const STYLE_CREATE_RESET = 'STYLE_CREATE_RESET';

export const STYLE_LIST_REQUEST = 'STYLE_LIST_REQUEST';
export const STYLE_LIST_SUCCESS = 'STYLE_LIST_SUCCESS';
export const STYLE_LIST_FAIL = 'STYLE_LIST_FAIL';

export const STYLE_DETAILS_REQUEST = 'STYLE_DETAILS_REQUEST';
export const STYLE_DETAILS_SUCCESS = 'STYLE_DETAILS_SUCCESS';
export const STYLE_DETAILS_FAIL = 'STYLE_DETAILS_FAIL';

export const STYLE_UPDATE_REQUEST = 'STYLE_UPDATE_REQUEST';
export const STYLE_UPDATE_SUCCESS = 'STYLE_UPDATE_SUCCESS';
export const STYLE_UPDATE_FAIL = 'STYLE_UPDATE_FAIL';
export const STYLE_UPDATE_RESET = 'STYLE_UPDATE_RESET';

export const STYLE_DELETE_REQUEST = 'STYLE_DELETE_REQUEST';
export const STYLE_DELETE_SUCCESS = 'STYLE_DELETE_SUCCESS';
export const STYLE_DELETE_FAIL = 'STYLE_DELETE_FAIL';
