// sizeActions.js
import axios from "axios";
import {
  SIZE_LIST_REQUEST,
  SIZE_LIST_SUCCESS,
  SIZE_LIST_FAIL,
  SIZE_DETAILS_REQUEST,
  SIZE_DETAILS_SUCCESS,
  SIZE_DETAILS_FAIL,
  SIZE_CREATE_REQUEST,
  SIZE_CREATE_SUCCESS,
  SIZE_CREATE_FAIL,
  SIZE_UPDATE_REQUEST,
  SIZE_UPDATE_SUCCESS,
  SIZE_UPDATE_FAIL,
  SIZE_DELETE_REQUEST,
  SIZE_DELETE_SUCCESS,
  SIZE_DELETE_FAIL,
} from "../constants/sizeConstants";
import { serverIp } from "../config/conf";

// Get all sizes
export const listSizes = () => async (dispatch) => {
  try {
    dispatch({ type: SIZE_LIST_REQUEST });

    const { data } = await axios.get(`${serverIp}/api/sizes`);

    dispatch({ type: SIZE_LIST_SUCCESS, payload: data.sizes });
  } catch (error) {
    dispatch({
      type: SIZE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Get size by ID
export const getSizeDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: SIZE_DETAILS_REQUEST });

    const { data } = await axios.get(`${serverIp}/api/sizes/${id}`);

    dispatch({ type: SIZE_DETAILS_SUCCESS, payload: data.size });
  } catch (error) {
    dispatch({
      type: SIZE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Create a size
export const createSize = (sizeData) => async (dispatch, getState) => {
  try {
    dispatch({ type: SIZE_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`${serverIp}/api/sizes`, sizeData, config);

    dispatch({ type: SIZE_CREATE_SUCCESS, payload: data.size });
  } catch (error) {
    dispatch({
      type: SIZE_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Update a size
export const updateSize = (id, sizeData) => async (dispatch, getState) => {
  try {
    dispatch({ type: SIZE_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`${serverIp}/api/sizes/${id}`, sizeData, config);

    dispatch({ type: SIZE_UPDATE_SUCCESS, payload: data.size });
  } catch (error) {
    dispatch({
      type: SIZE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Delete a size
export const deleteSize = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SIZE_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`${serverIp}/api/sizes/${id}`, config);

    dispatch({ type: SIZE_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: SIZE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
