import { MdOutlineShare } from "react-icons/md";

export default function ShareButton() {
  const productUrl = window.location.href;

  const handleShare = async () => {
    if (navigator.share) {
      try {
        const shareData = {
          title: "Check out this product!",
          text: "I found this great product for you!",
          url: productUrl,
        };

        await navigator.share(shareData);
        console.log("Product shared successfully");
      } catch (error) {
        console.error("Error sharing the product:", error);
      }
    } else {
      console.log("Web Share API not supported in this browser.");
    }
  };

  return (
    <button className="" onClick={handleShare}>
      <MdOutlineShare className="w-6 h-6" style={{ color: "#000080" }} />
    </button>
  );
}
