import {
  CREATE_COUPON_REQUEST,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_FAILURE,
  GET_ALL_COUPONS_REQUEST,
  GET_ALL_COUPONS_SUCCESS,
  GET_ALL_COUPONS_FAILURE,
  GET_COUPON_REQUEST,
  GET_COUPON_SUCCESS,
  GET_COUPON_FAILURE,
  DELETE_COUPON_REQUEST,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAILURE,
  CHECK_COUPON_FAILURE,
  CHECK_COUPON_REQUEST,
  CHECK_COUPON_SUCCESS,
  CREATE_COUPON_RESET,
  CHECK_COUPON_RESET,
  COUPON_UPDATE_REQUEST,
  COUPON_UPDATE_SUCCESS,
  COUPON_UPDATE_FAIL,
  COUPON_UPDATE_RESET,
  GET_COUPON_RESET,
} from "../constants/couponConstants";

// export const couponCreateReducer = (state = {}, action) => {
//   switch (action.type) {
//     case CREATE_COUPON_REQUEST:
//       return { loading: true };
//     case CREATE_COUPON_SUCCESS:
//       return { loading: false, success: true, ...action.payload };
//     case CREATE_COUPON_FAILURE:
//       return { loading: false, error: action.payload };
//     case CREATE_COUPON_RESET:
//       return {};
//     default:
//       return state;
//   }
// };

export const couponCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_COUPON_REQUEST:
      return { loading: true };
    case CREATE_COUPON_SUCCESS:
      return { loading: false, success: true, coupon: action.payload.coupon };
    case CREATE_COUPON_FAILURE:
      return { loading: false, error: action.payload };
    case CREATE_COUPON_RESET:
      return {}; // Clears all state after reset
    default:
      return state;
  }
};

// export const couponUpdateReducer = (state = { coupon: {} }, action) => {
//   switch (action.type) {
//     case COUPON_UPDATE_REQUEST:
//       return { ...state, loading: true };
//     case COUPON_UPDATE_SUCCESS:
//       return { loading: false, success: true, ...action.payload };
//     case COUPON_UPDATE_FAIL:
//       return { loading: false, error: action.payload };
//     case COUPON_UPDATE_RESET:
//       return { coupon: {}, success: false };
//     default:
//       return state;
//   }
// };

export const couponUpdateReducer = (state = { coupon: {} }, action) => {
  switch (action.type) {
    case COUPON_UPDATE_REQUEST:
      return { ...state, loading: true };
    case COUPON_UPDATE_SUCCESS:
      return { loading: false, success: true, coupon: action.payload };
    case COUPON_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case COUPON_UPDATE_RESET:
      return { coupon: {}, success: false };
    default:
      return state;
  }
};

export const couponDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_COUPON_REQUEST:
      return { loading: true };
    case DELETE_COUPON_SUCCESS:
      return { loading: false, success: true };
    case DELETE_COUPON_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// export const couponListReducer = (state = { coupons: [] }, action) => {
//   switch (action.type) {
//     case GET_ALL_COUPONS_REQUEST:
//       return { loading: true, coupons: [] };
//     case GET_ALL_COUPONS_SUCCESS:
//       return { loading: false, ...action.payload };
//     case GET_ALL_COUPONS_FAILURE:
//       return { loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };

export const couponListReducer = (state = { coupons: [] }, action) => {
  switch (action.type) {
    case GET_ALL_COUPONS_REQUEST:
      return { loading: true, coupons: [] };
    case GET_ALL_COUPONS_SUCCESS:
      return { loading: false, success: true, coupons: action.payload.coupons };
    case GET_ALL_COUPONS_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// export const couponValidationReducer = (
//   state = { isValid: false, discount: 0 },
//   action
// ) => {
//   switch (action.type) {
//     case CHECK_COUPON_REQUEST:
//       return { loading: true, isValid: false, discount: 0 };
//     case CHECK_COUPON_SUCCESS:
//       return {
//         loading: false,
//         isValid: action.payload.isValid,
//         discount: action.payload.discount,
//       };
//     case CHECK_COUPON_FAILURE:
//       return { loading: false, error: action.payload, discount: 0 };
//     case CHECK_COUPON_RESET:
//       return { isValid: false, discount: 0 };
//     default:
//       return state;
//   }
// };

export const couponValidationReducer = (
  state = { isValid: false, discount: 0 },
  action
) => {
  switch (action.type) {
    case CHECK_COUPON_REQUEST:
      return { loading: true, isValid: false, discount: 0 };
    case CHECK_COUPON_SUCCESS:
      return {
        loading: false,
        isValid: true,
        discount: action.payload.discount,
      };
    case CHECK_COUPON_FAILURE:
      return {
        loading: false,
        discount: 0,
        isValid: false,
        error: action.payload,
      };
    case CHECK_COUPON_RESET:
      return { isValid: false, discount: 0 };
    default:
      return state;
  }
};

// export const couponGetReducer = (state = { coupon: {} }, action) => {
//   switch (action.type) {
//     case GET_COUPON_REQUEST:
//       return { loading: true, coupon: {} };
//     case GET_COUPON_SUCCESS:
//       return { loading: false, ...action.payload };
//     case GET_COUPON_FAILURE:
//       return { loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };

export const couponGetReducer = (state = { coupon: {} }, action) => {
  switch (action.type) {
    case GET_COUPON_REQUEST:
      return { loading: true, coupon: {} };
    case GET_COUPON_SUCCESS:
      return { loading: false, coupon: action.payload };
    case GET_COUPON_FAILURE:
      return { loading: false, error: action.payload };
    case GET_COUPON_RESET:
      return { coupon: {} };
    default:
      return state;
  }
};
