export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL';
export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET';

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST';
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS';
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL';
export const ORDER_DETAILS_RESET = 'ORDER_DETAILS_RESET';

export const ORDER_PAY_REQUEST = 'ORDER_PAY_REQUEST';
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS';
export const ORDER_PAY_FAIL = 'ORDER_PAY_FAIL';
export const ORDER_PAY_RESET = 'ORDER_PAY_RESET';

export const ORDER_MY_LIST_REQUEST = 'ORDER_MY_LIST_REQUEST';
export const ORDER_MY_LIST_SUCCESS = 'ORDER_MY_LIST_SUCCESS';
export const ORDER_MY_LIST_FAIL = 'ORDER_MY_LIST_FAIL';
export const ORDER_MY_LIST_RESET = 'ORDER_MY_LIST_RESET';

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST';
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS';
export const ORDER_LIST_FAIL = 'ORDER_MY_LIST_FAIL';

export const ORDER_DELIVER_REQUEST = 'ORDER_DELIVER_REQUEST';
export const ORDER_DELIVER_SUCCESS = 'ORDER_DELIVER_SUCCESS';
export const ORDER_DELIVER_FAIL = 'ORDER_DELIVER_FAIL';
export const ORDER_DELIVER_RESET = 'ORDER_DELIVER_RESET';

export const ORDER_STATUS_REQUEST = 'ORDER_STATUS_REQUEST';
export const ORDER_STATUS_SUCCESS = 'ORDER_STATUS_SUCCESS';
export const ORDER_STATUS_FAIL = 'ORDER_STATUS_FAIL';
export const ORDER_STATUS_RESET = 'ORDER_STATUS_RESET';

export const ORDER_STATS_REQUEST = 'ORDER_STATS_REQUEST';
export const ORDER_STATS_SUCCESS = 'ORDER_STATS_SUCCESS';
export const ORDER_STATS_FAIL = 'ORDER_STATS_FAIL';

export const CLAIM_WARRANTY_REQUEST = 'CLAIM_WARRANTY_REQUEST';
export const CLAIM_WARRANTY_SUCCESS = 'CLAIM_WARRANTY_SUCCESS';
export const CLAIM_WARRANTY_FAILURE = 'CLAIM_WARRANTY_FAILURE';
export const CLAIM_WARRANTY_RESET = 'CLAIM_WARRANTY_RESET';

export const USER_WARRANTY_CLAIMS_REQUEST = 'USER_WARRANTY_CLAIMS_REQUEST';
export const USER_WARRANTY_CLAIMS_SUCCESS = 'USER_WARRANTY_CLAIMS_SUCCESS';
export const USER_WARRANTY_CLAIMS_FAILURE = 'USER_WARRANTY_CLAIMS_FAILURE';