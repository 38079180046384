import axios from 'axios';
import {
    COLLECTION_CREATE_REQUEST,
    COLLECTION_CREATE_SUCCESS,
    COLLECTION_CREATE_FAIL,
    COLLECTION_LIST_REQUEST,
    COLLECTION_LIST_SUCCESS,
    COLLECTION_LIST_FAIL,
    COLLECTION_DETAILS_REQUEST,
    COLLECTION_DETAILS_SUCCESS,
    COLLECTION_DETAILS_FAIL,
    COLLECTION_UPDATE_REQUEST,
    COLLECTION_UPDATE_SUCCESS,
    COLLECTION_UPDATE_FAIL,
    COLLECTION_DELETE_REQUEST,
    COLLECTION_DELETE_SUCCESS,
    COLLECTION_DELETE_FAIL,
} from '../constants/collectionConstants';
import { serverIp } from '../config/conf';

export const createCollection = (collectionData) => async (dispatch, getState) => {
    try {
        dispatch({ type: COLLECTION_CREATE_REQUEST });

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.post(`${serverIp}/api/collection`, collectionData, config);

        dispatch({
            type: COLLECTION_CREATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: COLLECTION_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const listCollections = () => async (dispatch) => {
    try {
        dispatch({ type: COLLECTION_LIST_REQUEST });

        const { data } = await axios.get(`${serverIp}/api/collection`);

        dispatch({
            type: COLLECTION_LIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: COLLECTION_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getCollectionDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: COLLECTION_DETAILS_REQUEST });

        const { data } = await axios.get(`${serverIp}/api/collection/${id}`);

        dispatch({
            type: COLLECTION_DETAILS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: COLLECTION_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const updateCollection = (collectionData) => async (dispatch, getState) => {
    try {
        dispatch({ type: COLLECTION_UPDATE_REQUEST });

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.put(`${serverIp}/api/collection/${collectionData._id}`, collectionData, config);

        dispatch({
            type: COLLECTION_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: COLLECTION_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteCollection = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: COLLECTION_DELETE_REQUEST });

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        await axios.delete(`${serverIp}/api/collection/${id}`, config);

        dispatch({
            type: COLLECTION_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: COLLECTION_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};
