import { LockClosedIcon } from "@heroicons/react/20/solid";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";

import { resetPassword as resetPass } from "../actions/userActions";
import { scrollToTop } from "../components/ScrollToTop";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

export default function ResetPass() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  let redirect = searchParams.get("redirect") || "/otp?type=0";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [message, setMessage] = useState(null);
  const [err, setErr] = useState("");
  const [showPass, setShowPass] = useState(false);

  const userRegister = useSelector((state) => state.userRegister);
  const { error, userInfo } = userRegister;

  const forgotPassword = useSelector((state) => state.forgotPassword);
  const { successOTP, email: emailOTP } = forgotPassword;

  const resetPassword = useSelector((state) => state.resetPassword);
  const { success } = resetPassword;

  useEffect(() => {
    scrollToTop();
    if (success) {
      navigate("/signup");
    }
    if (successOTP && emailOTP) {
      setEmail(emailOTP);
    }
  }, [navigate, userInfo, redirect, success, successOTP, emailOTP]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    } else {
      dispatch(resetPass(email, password));
    }
  };

  const validatePassword = (value) => {
    const minLength = 7;
    const hasNumber = /\d/.test(value);
    const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(value);

    if (value.length < minLength) {
      return "Password must be at least 7 characters long, must contain at least 1 number & 1 special character.";
    } else if (!hasNumber) {
      return "Password must be at least 7 characters long, must contain at least 1 number & 1 special character.";
    } else if (!hasSpecialChar) {
      return "Password must be at least 7 characters long, must contain at least 1 number & 1 special character.";
    } else {
      return "";
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    const error = validatePassword(newPassword);
    setPasswordError(error);
  };

  return (
    <div className="flex min-h-full border-b-2 items-center justify-center px-4 py-4 sm:px-6 lg:px-8 bg-white">
      <div className="w-full max-w-md space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight">
            Reset Password
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={submitHandler}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="flex flex-col gap-4 -space-y-px rounded-md shadow-sm">
            <div>
              <label htmlFor="password" className="flex">
                <p>Password</p>
                <p className="text-red-500">*</p>
              </label>
              <InputGroup>
                <Input
                  id="password"
                  border="1px solid black"
                  rounded="none"
                  name="password"
                  type={showPass ? "text" : "password"}
                  autoComplete="current-password"
                  required
                  bg="white"
                  placeholder=" Password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <InputRightElement bg="white" border="1px solid black">
                  <button
                    className="bg-white"
                    type="button"
                    onClick={() => setShowPass(!showPass)}
                  >
                    {showPass ? (
                      <AiFillEyeInvisible
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    ) : (
                      <AiFillEye className="h-5 w-5" aria-hidden="true" />
                    )}
                  </button>
                </InputRightElement>
              </InputGroup>
              {passwordError && <p className="text-red-500">{passwordError}</p>}
            </div>
            <div>
              <label htmlFor="confirmpassword" className="flex">
                <p>Confirm Password</p>
                <p className="text-red-500">*</p>
              </label>
              <input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                autoComplete="current-password"
                required
                className="relative block w-full"
                placeholder=" Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>

          <div className="flex justify-center items-center">
            <button
              type="submit"
              className="group relative flex w-1/2 justify-center rounded-md bg-[#8e295b] px-3 py-2 text-sm font-semibold text-white hover:bg-[#6b1d45] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <LockClosedIcon
                  className="h-5 w-5 text-white"
                  aria-hidden="true"
                />
              </span>
              Reset
            </button>
          </div>
        </form>
        {error && <div>{error}</div>}
        {message && <div>{message}</div>}
      </div>
    </div>
  );
}
