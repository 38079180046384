import {
  SUB_CATEGORY_CREATE_REQUEST,
  SUB_CATEGORY_CREATE_SUCCESS,
  SUB_CATEGORY_CREATE_FAIL,
  SUB_CATEGORY_CREATE_RESET,
  SUB_CATEGORY_LIST_REQUEST,
  SUB_CATEGORY_LIST_SUCCESS,
  SUB_CATEGORY_LIST_FAIL,
  SUB_CATEGORY_DETAILS_REQUEST,
  SUB_CATEGORY_DETAILS_SUCCESS,
  SUB_CATEGORY_DETAILS_FAIL,
  SUB_CATEGORY_UPDATE_REQUEST,
  SUB_CATEGORY_UPDATE_SUCCESS,
  SUB_CATEGORY_UPDATE_FAIL,
  SUB_CATEGORY_UPDATE_RESET,
  SUB_CATEGORY_DELETE_REQUEST,
  SUB_CATEGORY_DELETE_SUCCESS,
  SUB_CATEGORY_DELETE_FAIL,
} from "../constants/subCategoryConstants";

const initialState = {
  subcategories: [],
};

export const subCategoryCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUB_CATEGORY_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case SUB_CATEGORY_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
        success: true,
      };
    case SUB_CATEGORY_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case SUB_CATEGORY_CREATE_RESET:
      return {
        ...state,
        subcategories: [],
        loading: false,
        error: null,
        success: false,
      };
    default:
      return state;
  }
};

export const subCategoryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUB_CATEGORY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SUB_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case SUB_CATEGORY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const subCategoryDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUB_CATEGORY_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SUB_CATEGORY_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case SUB_CATEGORY_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const subCategoryUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUB_CATEGORY_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SUB_CATEGORY_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
        success: true,
      };
    case SUB_CATEGORY_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SUB_CATEGORY_UPDATE_RESET:
      return {
        ...state,
        subcategories: [],
        loading: false,
        error: null,
        success: false,
      };
    default:
      return state;
  }
};

export const subCategoryDeleteReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUB_CATEGORY_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case SUB_CATEGORY_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case SUB_CATEGORY_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    default:
      return state;
  }
};
