import {
    MENU_LINK_CREATE_REQUEST,
    MENU_LINK_CREATE_SUCCESS,
    MENU_LINK_CREATE_FAIL,
    MENU_LINK_LIST_REQUEST,
    MENU_LINK_LIST_SUCCESS,
    MENU_LINK_LIST_FAIL,
    MENU_LINK_DETAILS_REQUEST,
    MENU_LINK_DETAILS_SUCCESS,
    MENU_LINK_DETAILS_FAIL,
    MENU_LINK_UPDATE_REQUEST,
    MENU_LINK_UPDATE_SUCCESS,
    MENU_LINK_UPDATE_FAIL,
    MENU_LINK_DELETE_REQUEST,
    MENU_LINK_DELETE_SUCCESS,
    MENU_LINK_DELETE_FAIL,
    MENU_LINK_CREATE_RESET,
    MENU_LINK_UPDATE_RESET
} from '../constants/menuConstants';

const initialState = {
    menuLink: null,
    menuLinks: [],
    loading: false,
    error: null,
    success: false
};

export const menuLinkCreateReducer = (state = initialState, action) => {
    switch (action.type) {
        case MENU_LINK_CREATE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false
            };
        case MENU_LINK_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                menuLink: action.payload,
                success: true,
            };
        case MENU_LINK_CREATE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false
            };
        case MENU_LINK_CREATE_RESET:
            return {
                ...state,
                menuLink: null,
                menuLinks: [],
                loading: false,
                error: null,
                success: false
            };
        default:
            return state;
    }
};

export const menuLinkListReducer = (state = initialState, action) => {
    switch (action.type) {
        case MENU_LINK_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case MENU_LINK_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                menuLinks: action.payload,
            };
        case MENU_LINK_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const menuLinkDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case MENU_LINK_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case MENU_LINK_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                menuLink: action.payload,
            };
        case MENU_LINK_DETAILS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const menuLinkUpdateReducer = (state = initialState, action) => {
    switch (action.type) {
        case MENU_LINK_UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case MENU_LINK_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                menuLink: action.payload,
                success: true,
            };
        case MENU_LINK_UPDATE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case MENU_LINK_UPDATE_RESET:
            return {
                ...state,
                menuLink: null,
                menuLinks: [],
                loading: false,
                error: null,
                success: false
            }
        default:
            return state;
    }
};

export const menuLinkDeleteReducer = (state = initialState, action) => {
    switch (action.type) {
        case MENU_LINK_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false
            };
        case MENU_LINK_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case MENU_LINK_DELETE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false
            };
        default:
            return state;
    }
};
