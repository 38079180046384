// AddressForm.js
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import React, { useState, Fragment, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

const AddressForm = ({ isOpen, onClose, onSave, addressData }) => {
  const [formData, setFormData] = useState({});
  const [selectedLabel, setSelectedLabel] = useState("Home");
  const [customLabel, setCustomLabel] = useState("");
  const formRef = useRef(null);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (addressData !== null) {
      setFormData(addressData);
    } else {
      setFormData({
        name: userInfo.firstName,
        number:
          userInfo?.phone?.split("+91") !== undefined
            ? userInfo?.phone?.split("+91")[1]
            : userInfo?.phone?.split("+91"),
        label: "Home",
        address: "",
        town: "",
        city: "",
        state: "",
        postalCode: "",
        country: "India",
        isDefault: false,
        landmark: "",
        area: "",
      });
    }
  }, [addressData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "radio") {
      setSelectedLabel(value);
      if (value !== "other") {
        setFormData((prevData) => ({
          ...prevData,
          label: value,
        }));
      }
    } else if (name === "customLabel") {
      setCustomLabel(value);
      setFormData((prevData) => ({
        ...prevData,
        label: value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const fetchStateCity = async (e) => {
    const { name, value, type } = e.target;
    if (value?.length === 6) {
      const { data } = await axios.get(
        `https://api.postalpincode.in/pincode/${value}`
      );
      setFormData((prevData) => ({
        ...prevData,
        city: data[0]?.PostOffice[0].District,
        state: data[0]?.PostOffice[0].State,
      }));
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    onSave(formData);
    setSelectedLabel("");
    setCustomLabel("");
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmitButtonClick = (e) => {
    formRef.current.reportValidity(); // Triggers validation on the form
    if (formRef.current.checkValidity()) {
      // Checks if the form is valid
      handleSave(e);
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[210]" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Add/Edit Address
                  </Dialog.Title>
                  <div className="flex flex-col gap-6 mt-4">
                    <form
                      id="formAdd"
                      className="space-y-6 grid grid-cols-2 gap-x-6"
                      ref={formRef} // Assign the form ref here
                      onSubmit={handleSave} // Attach submit event handler
                    >
                      {/* Address Label */}
                      <div className="mb-4 col-span-2">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                          Address Label
                        </label>
                        <div className="flex gap-4 items-center">
                          <label className="flex items-center gap-2">
                            <input
                              type="radio"
                              name="label"
                              value="Home"
                              checked={selectedLabel === "Home"}
                              onChange={handleChange}
                              className="form-radio text-[#000080]"
                            />
                            Home
                          </label>

                          <label className="flex items-center gap-2">
                            <input
                              type="radio"
                              name="label"
                              value="Office"
                              checked={selectedLabel === "Office"}
                              onChange={handleChange}
                              className="form-radio text-[#000080]"
                            />
                            Office
                          </label>

                          <label className="flex items-center gap-2">
                            <input
                              type="radio"
                              name="label"
                              value="Other"
                              checked={selectedLabel === "Other"}
                              onChange={handleChange}
                              className="form-radio text-[#000080]"
                            />
                            Other
                          </label>

                          {selectedLabel === "Other" && (
                            <input
                              type="text"
                              name="customLabel"
                              value={customLabel}
                              onChange={handleChange}
                              placeholder="Enter custom label"
                              className="border-gray-300 rounded-md px-3 py-2 focus:border-[#000080] focus:ring-[#000080] block w-full"
                            />
                          )}
                        </div>
                      </div>

                      {/* Address Input */}
                      <div className="mb-4 col-span-2">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                          Full Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          required
                          value={formData?.name}
                          onChange={handleChange}
                          className="border-gray-300 rounded-md px-3 py-2 focus:border-[#000080] focus:ring-[#000080] block w-full"
                          placeholder="Enter your name"
                        />
                      </div>

                      {/* Address Input */}
                      <div className="mb-4 col-span-2">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                          Phone
                        </label>
                        <input
                          type="tel"
                          name="number"
                          required
                          value={formData?.number}
                          onChange={handleChange}
                          className="border-gray-300 rounded-md px-3 py-2 focus:border-[#000080] focus:ring-[#000080] block w-full"
                          placeholder="Enter your number"
                        />
                      </div>

                      {/* Address Input */}
                      <div className="mb-4 col-span-2">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                          Address
                        </label>
                        <input
                          type="text"
                          name="address"
                          required
                          value={formData?.address}
                          onChange={handleChange}
                          className="border-gray-300 rounded-md px-3 py-2 focus:border-[#000080] focus:ring-[#000080] block w-full"
                          placeholder="Enter your address"
                        />
                      </div>

                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                          Area
                        </label>
                        <input
                          type="text"
                          name="area"
                          value={formData?.area}
                          onChange={handleChange}
                          className="border-gray-300 rounded-md px-3 py-2 focus:border-[#000080] focus:ring-[#000080] block w-full"
                          placeholder="Enter your area"
                        />
                      </div>

                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                          Landmark
                        </label>
                        <input
                          type="text"
                          name="landmark"
                          value={formData?.landmark}
                          onChange={handleChange}
                          className="border-gray-300 rounded-md px-3 py-2 focus:border-[#000080] focus:ring-[#000080] block w-full"
                          placeholder="Enter your landmark"
                        />
                      </div>

                      {/* Postal Code Input */}
                      <div className="mb-4 col-span-2">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                          Postal Code
                        </label>
                        <input
                          type="number"
                          name="postalCode"
                          required
                          value={formData?.postalCode}
                          onChange={handleChange}
                          onBlur={fetchStateCity}
                          className="border-gray-300 rounded-md px-3 py-2 focus:border-[#000080] focus:ring-[#000080] block w-full"
                          placeholder="Enter your postal code"
                        />
                      </div>

                      {/* City Input */}
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                          City
                        </label>
                        <input
                          type="text"
                          name="city"
                          value={formData?.city}
                          onChange={handleChange}
                          className="border-gray-300 rounded-md px-3 py-2 focus:border-[#000080] focus:ring-[#000080] block w-full"
                          placeholder="Enter your city"
                        />
                      </div>

                      {/* State Input */}
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                          State
                        </label>
                        <input
                          type="text"
                          name="state"
                          value={formData?.state}
                          onChange={handleChange}
                          className="border-gray-300 rounded-md px-3 py-2 focus:border-[#000080] focus:ring-[#000080] block w-full"
                          placeholder="Enter your state"
                        />
                      </div>

                      {/* Country Input */}
                      {/* <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                          Country
                        </label>
                        <input
                          type="text"
                          name="country"
                          value={formData?.country}
                          onChange={handleChange}
                          className="border-gray-300 rounded-md px-3 py-2 focus:border-[#000080] focus:ring-[#000080] block w-full"
                          placeholder="Enter your country"
                        />
                      </div> */}
                    </form>

                    <div className="mt-6 flex justify-end space-x-4">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-400"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                      <button
                        type="button" // Change to type="button" to prevent form submit on click
                        onClick={(e) => handleSubmitButtonClick(e)} // Trigger submit handler
                        className="inline-flex justify-center rounded-md border border-transparent bg-[#000080] px-4 py-2 text-sm font-medium text-white hover:bg-[#000060]"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AddressForm;
